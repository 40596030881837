<div class="toolbar" id="kt_toolbar">
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{'GLOBAL_MESSAGES' | translate}}</h1>
            <span class="h-20px border-gray-200 border-start mx-4"></span>

            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'HOME' | translate}}</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <!-- <li class="breadcrumb-item text-muted">Widgets</li> -->
                <!--end::Item-->
                <!--begin::Item-->
                <!-- <li class="breadcrumb-item">
                    <span class="bullet bg-gray-200 w-5px h-2px"></span>
                </li> -->
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-dark">{{'GLOBAL_MESSAGES' | translate}}</li>
                <!--end::Item-->
            </ul>
        </div>
    </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl">
        <div class="card mb-5 mb-xl-8">
            <form [formGroup]="globalMessagesForm" (submit)="globalMessages()">
                <div class="card-body py-6">
                    <span class="input-text">{{'GLOBAL_MESSAGES' | translate}}</span>
                    <textarea class="form-control" rows="15" formControlName="message"></textarea>
                </div>
                <div class="card-footer d-flex flex-row-reverse py-6">
                    <button type="submit" class="btn btn-primary" [disabled]="globalMessagesForm.invalid">{{'CONFIRM' | translate}}</button>
                    <button type="button" class="btn btn-warning me-2" (click)="clear()">{{'CLEAR' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>





