import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../../core/services/auth/auth.service';
import {DataService} from "../../../../core/services/data/data.service";
import {Store} from "@ngxs/store";

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {
  public transferForm: FormGroup;
  public transferToManager = '1';
  public managerList = [];
  public user;
  public managerInfo;
  public weeks = [];
  public weekIndex = 0;
  public balance: number = null;
  public query = null;
  constructor(private adminService: AdminService,
              private authService: AuthService,
              private dataservice: DataService,
              private store: Store,
              private translateService: TranslateService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.loadManager();
    this.createTransferForm();
  }

  createTransferForm(): void {
    this.transferForm = new FormGroup({
      userId: new FormControl('', Validators.required),
      amount: new FormControl('', Validators.required),
      currencyId: new FormControl(1, Validators.required),
      description: new FormControl('', []),
    }, {
      updateOn: 'change'
    });
  }

  loadManager(): void{
    const data = {
      Role: 101,
      PageNumber: 1,
      PageSize: 10000
    };
    this.adminService.getAllManager(data).subscribe(
      res => {
        this.managerList = res.result;
        if(res.result.length) {
          this.transferForm.controls['userId'].setValue(res.result[0].userId);
        }
      },
      err => console.log(err)
    )
  }


  transfer(): void{
    console.log(this.transferForm.value)
    console.log(this.transferToManager)

    this.transferForm.markAllAsTouched();
    if (this.transferForm.invalid) { return; }

    if(this.transferToManager === '1') {
      this.transferTo(this.transferForm.value);
    }

    if(this.transferToManager === '0') {
      this.transferFrom(this.transferForm.value);
    }

  }


  transferTo(data): void {
    this.adminService.transferToManager(data).subscribe(
      res => {
        this.createTransferForm();

        this.toastrService.success('Player is successful created.');
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('Server not responding');
      }
    );
  }

  transferFrom(data): void {
    this.adminService.transferFromManager(data).subscribe(
      res => {
        this.createTransferForm();
        this.toastrService.success('Money successful transferred.');
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('Server not responding');
      }
    );
  }

  clear(): void {
    this.createTransferForm();
  }

}
