import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  Login,
  Logout,
  VerifyOtp,
  SetUserKey
} from './auth.actions';
import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';

export interface AuthStateModel {
  token: string;
  verificationType: number;
  deviceId: string;
  user: any;
  userKey: string;
}

@Injectable()

@State<AuthStateModel>({
  name: 'eliteAuth',
  defaults: {
    token: null,
    deviceId: null,
    verificationType: null,
    user: null,
    userKey: null
  }
})

export class AuthState {

  constructor(private ngZone: NgZone,
              private router: Router) {
  }

  @Selector()
  static getDeviceId(state: AuthStateModel): any {
    return state.deviceId || null;
  }

  @Selector()
  static getUserRole(state: AuthStateModel): any {
    return state.user.role || null;
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, {data}: Login): void {
    ctx.patchState({
      token: data.token,
      user: data.user,
      deviceId: data.deviceId,
    });
  }

  @Action(VerifyOtp)
  verifyOtp(ctx: StateContext<AuthStateModel>, {data}: VerifyOtp): void {
    ctx.patchState({
      token: data.token,
      user: data.user,
      deviceId: data.deviceId,
      verificationType: data.verificationType,
    });
  }

  @Action(Logout)
   logout(ctx: StateContext<AuthStateModel>, action: Logout): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      token: null
    });
    this.ngZone.run(() => this.router.navigate(['/login']));
  }

  @Action(SetUserKey)
  setUserKey(ctx: StateContext<AuthStateModel>, {userKey}: SetUserKey): void {
    ctx.patchState({
      userKey
    });
  }
}
