import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  constructor(private http: HttpClient) { }

  createPlayer(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/pokermanager/user`, data);
  }

  transferFromManager(data: any): Observable<any> {
    return this.http.post<any>( environment.serverAccountsURL + `/account/transferfromsub`, data);
  }

  transferToManager(data: any): Observable<any> {
    return this.http.post<any>( environment.serverAccountsURL + `/account/transfer`, data);
  }


  changePlayerPassword(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/pokermanager/changepassword`, data);
  }

  editPlayer(data: any, userId: any): Observable<any> {
    return this.http.put<any>( environment.serverPokerURL + `/pokermanager/user/${userId}`, data);
  }

  getManagerPlayers(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/pokermanager/users`, {params: {...data}});
  }

  getMyStatus(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/pokermanager/managerstats`, {params: {...data}});
  }

  getManagerWeek(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/adminmanager/managers`, {params:{...data}});
  }

  blockUser(data, userId): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/pokermanager/${userId}/blockuser`, data);
  }

  unblockUser(data, userId): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/pokermanager/${userId}/unblockuser`, data);
  }

  deleteUser(userId: any): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/pokermanager/${userId}/delete`, {});
  }

  changeWeeklyStart(data, userId: any): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/pokermanager/manager/${userId}/weeklystart`, data);
  }
}
