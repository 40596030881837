import { Component, OnInit } from '@angular/core';
import {ManagerUserWeekQuery} from '../../../../core/models/ManagerUserWeekQuery';
import {WeekEnum} from '../../../../core/enum/WeekEnum';
import {UserService} from '../../../../core/services/user/user.service';
import {ManagerService} from '../../../../core/services/manager/manager.service';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngxs/store';
import {DataService} from '../../../../core/services/data/data.service';
import {concatMap, tap} from 'rxjs/operators';
import {RegionalManagerService} from '../../../../core/services/regional-manager/regional-manager.service';

@Component({
  selector: 'app-main-regional',
  templateUrl: './main-regional.component.html',
  styleUrls: ['./main-regional.component.scss']
})
export class MainRegionalComponent implements OnInit {
  public user;
  public managerInfo;

  public query: ManagerUserWeekQuery = new ManagerUserWeekQuery(1, 10, 0, null);
  public userList = [];
  public weekEnum = WeekEnum;
  public weekIndex = 0;
  public weeks = [];
  public totals = null;
  public collectionSize = null;
  constructor(private userService: UserService,
              private managerService: RegionalManagerService,
              private route: ActivatedRoute,
              private store: Store,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.user = this.store.snapshot().eliteAuth.user;


    this.dataService.getWeeks().pipe(
      tap((res) => console.log(res)),
      tap(weeks => {
        this.weeks = weeks;
        return this.query = new ManagerUserWeekQuery(1, 10,  weeks[this.weekIndex].id, this.user.userId);
      }),
      concatMap(() => {
        return  this.managerService.getMyStatus(this.query)}
      )).subscribe(
      res => {
        this.collectionSize = res.count;
        this.managerInfo = res;
        console.log(res);
      },
      err => console.log(err)
    )
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getUserWeekly();
  }

  getUserWeekly(): void {
    this.query.WeekNumber = this.weeks[this.weekIndex].id
    this.managerService.getMyStatus(this.query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.managerInfo = res;
      },
      err => console.log(err)
    )
  }
}
