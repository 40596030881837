<div class="toolbar" id="kt_toolbar">
  <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
      <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{weekEnum[weekIndex]}}</h1>
      <span class="h-20px border-gray-200 border-start mx-4"></span>
      <!--end::Separator-->
      <!--begin::Breadcrumb-->
      <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'HOME' | translate}}</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="breadcrumb-item text-muted">Widgets</li> -->
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li> -->
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-dark">{{weekEnum[weekIndex]}}</li>
      </ul>
    </div>
  </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl container-full-width">
    <div class="card mb-5 mb-xl-8">
      <div class="card-body py-3">
        <div class="table-responsive">
          <table class="table table-row-dashed table-row-gray-300 align-middle gy-4 gs-5">
            <thead>
            <tr>
              <th scope="col">{{'USERNAME' | translate}}</th>
              <th scope="col">{{'NICKNAME' | translate}}</th>
              <th scope="col">{{'WEEKLY_START' | translate}}</th>
              <th scope="col">{{'NEXT_WEEKLY_START' | translate}}</th>
              <th scope="col">{{'BALANCE' | translate}}</th>
              <th scope="col">{{'ADDED_BALANCE' | translate}}</th>
              <th scope="col">{{'WIN/LOST' | translate}}</th>
              <th scope="col">{{'PROFIT' | translate}}</th>
              <th scope="col">{{'ROULETE' | translate}}</th>
              <th scope="col">{{'TOTAL' | translate}}</th>
              <th scope="col">{{'STATUS' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of userList">
              <td>{{user.username}}</td>
              <td>{{user.fullName}}</td>
              <td class="text-nowrap">
                <span class="me-3">{{user.weeklyStart  | number:'1.2-2'}}</span>
                <button type="button" class="btn btn-primary btn-sm  px-2" data-bs-toggle="modal" (click)="selectUser(user.userId)" data-bs-target="#kt_modal_1">
                  <svg style="margin-top: -3px; margin-right: -3px;" fill="none" height="18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m14 2 4 4L7 17H3v-4L14 2zM3 22h18"/></svg>
                </button>
              </td>
              <td>{{user.nextWeeklyStart | number:'1.2-2'}}</td>
              <td>{{user.playersBalance  | number:'1.2-2'}}</td>
              <td>{{user.pointsAdded  | number:'1.2-2'}}</td>
              <td>{{(user.weeklyStart + user.pointsAdded - user.playersBalance)  | number:'1.2-2'}}</td>

              <td class="bg-green">{{user.managerCommission | number:'1.2-2'}}</td>
              <td class="bg-green">{{(user.roulletteWin-user.roulletteSpent) | number:'1.2-2'}}</td>
              <td>{{((user.roulletteWin-user.roulletteSpent) - user.managerCommission) | number:'1.2-2'}}</td>
              <td>
                <div class="form-check form-switch form-check-custom form-check-solid">
                  <input class="form-check-input" type="checkbox" (click)="changeStatus(user)" [(ngModel)]="!user.status" id="flexSwitchDefault" autocomplete="off" />
                  <label class="form-check-label" for="flexSwitchDefault">
                    OFF/ON
                  </label>
                </div>
              </td>

            </tr>
            </tbody>
            <tfoot *ngIf="userList.length">
            <tr>
              <td><strong>{{'TOTAL' | translate}}:</strong></td>
              <td></td>
              <td class="text-nowrap">
                <span class="me-3">{{totals.weeklyStart  | number:'1.2-2'}}</span>
                <button type="button" class="btn btn-primary btn-sm  px-2" data-bs-toggle="modal" (click)="selectUser(user.userId)" data-bs-target="#kt_modal_1">
                  <svg style="margin-top: -3px; margin-right: -3px;" fill="none" height="18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m14 2 4 4L7 17H3v-4L14 2zM3 22h18"/></svg>
                </button>
              </td>
              <td>{{totals.nextWeeklyStart | number:'1.2-2'}}</td>
              <td>{{totals.playersBalance  | number:'1.2-2'}}</td>
              <td>{{totals.pointsAdded  | number:'1.2-2'}}</td>
              <td>{{(totals.weeklyStart + totals.pointsAdded - totals.playersBalance)  | number:'1.2-2'}}</td>

              <td class="bg-green">{{totals.managerCommission | number:'1.2-2'}}</td>
              <td class="bg-green">{{(totals.roulletteWin-totals.roulletteSpent) | number:'1.2-2'}}</td>
              <td>{{((totals.roulletteWin-totals.roulletteSpent) - totals.managerCommission) | number:'1.2-2'}}</td>
              <td>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [(pageSize)]='query.PageSize' [(page)]="query.PageNumber" (pageChange)="changePage($event)"></ngb-pagination>
  </div>
</div>


<div class="modal fade" tabindex="-1" id="kt_modal_1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Edit Weekly Start</h5>
        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" id="closeModal" #closeWeekModal data-bs-dismiss="modal" aria-label="Close">
          <span class="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="1" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
              </svg>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <div class="modal-body">
        <form class="card mb-5 mb-xl-8" [formGroup]="weeklyStartForm" (ngSubmit)="changeWeeklyStart()">
          <div class="card-body py-6">
            <div class="row mb-3">
              <div class="col-xs-12 col-md-4">
                <p class="mb-0 mt-4">New Weekly Start</p>
              </div>
              <div class="col-xs-12 col-md-8">
                <input type="number" class="form-control"  formControlName="weeklyStart" placeholder="New Weekly Start" autocomplete="off" />
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between py-6">
            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-warning">Cancel</button>
            <button type="submit" class="btn btn-primary" [disabled]="weeklyStartForm.invalid">Confirm</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

