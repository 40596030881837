<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl container-full-width">
    <div class="card mb-5 mb-xl-8">

      <div class="card-body py-3">
        <div class="table-responsive">
          <table class="table table-row-dashed table-striped table-row-gray-300 align-middle gy-4 gs-5">
            <thead>
            <tr>
              <th scope="col">{{'ERROR_DESCRIPTION' | translate}}</th>
              <th scope="col">{{'TIME' | translate}}</th>
              <th scope="col">{{'USER_ID' | translate}}User ID</th>
              <th scope="col">{{'PAGE_URL' | translate}}Page Url</th>
              <th scope="col">{{'DEVICE_ID' | translate}}Device ID</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of errorsList">
              <td>{{item?.errorDescription | slice : 0 : 200}}</td>
              <td>{{item?.time | date:"medium"}}</td>
              <td>{{item?.userId}}</td>
              <td>{{item?.pageUrl}}</td>
              <td>{{item?.deviceId}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
