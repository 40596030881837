import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

constructor( private injector: Injector, private store: Store) {}
  intercept( req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authToken = 'Bearer ' + this.store.snapshot().eliteAuth.token;
        const reqCopy = req.clone({ headers: req.headers.set('Authorization', authToken).append('Accept', 'application/json')});
        return next.handle(reqCopy);
  }
}
