<div class="modal fade" tabindex="-1" id="kt_modal_1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'RESET_PASSWORD' | translate}}</h5>
        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" id="closeModal" #closeModal data-bs-dismiss="modal" aria-label="Close">
                    <span class="svg-icon svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="1" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                        </svg>
                    </span>
        </div>
        <!--end::Close-->
      </div>
      <div class="modal-body">
        <form class="card mb-5 mb-xl-8" [formGroup]="changePasswordForm" (submit)="change()">
          <div class="card-body py-6">
            <div class="row mb-3">
              <div class="col-xs-12 col-md-4">
                <p class="mb-0 mt-4">{{'PASSWORD' | translate}}</p>
              </div>
              <div class="col-xs-12 col-md-8">
                <input type="password" class="form-control"  formControlName="newPassword" placeholder="{{'PASSWORD' | translate}}" autocomplete="off" />
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between py-6">
            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-warning">{{'CANCEL' | translate}}</button>
            <button type="submit" class="btn btn-primary" [disabled]="changePasswordForm.invalid">{{'CONFIRM' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" id="kt_modal_2">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><span class="h2 text-capitalize " *ngIf="specUserManagerData">{{specUserManagerData.username}}</span> <br>{{'POKER_DETAILS' | translate}}</h5>
        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" id="closeModal2" #closeModal2 data-bs-dismiss="modal" aria-label="Close">
                    <span class="svg-icon svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="1" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                        </svg>
                    </span>
        </div>
        <!--end::Close-->
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-row-dashed table-striped table-row-gray-300 align-middle gy-4 gs-5">
            <thead>
            <tr>
              <th scope="col">{{'POINTS_ADDED(B)' | translate}}</th>
              <th scope="col">{{'POINTS_SPENT' | translate}}</th>
              <th scope="col">{{'POINT_AVAILABLE(C)' | translate}}</th>
              <th scope="col">{{'TOTAL_COMS' | translate}}</th>
              <th scope="col">{{'JACKPOT_COMS' | translate}}</th>
              <th scope="col">{{'HOUSE_COMMS' | translate}}</th>
              <th scope="col">{{'MANAGER_COMMS' | translate}}</th>
              <th scope="col">{{'WIN_LOST_FORMULA' | translate}}</th>
              <th scope="col">{{'WIN_LOST_FORMULA_COMS' | translate}}</th>
              <th scope="col">{{'JACKPOT_AMOUNT' | translate}}</th>
              <th scope="col">{{'MANUALLY_ADDED_JACKPOT' | translate}}</th>
              <th scope="col">{{'TOTAL' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="specUserManagerData">
              <td>{{specUserManagerData.managerPointsAdded | number:'1.2-2'}}</td>
              <td>{{specUserManagerData.managerWeeklyStart + specUserManagerData.managerPointsAdded -  specUserManagerData.managerCurrentBalance | number:'1.2-2'}}</td>
              <td>{{(specUserManagerData.managerCurrentBalance) | number:'1.2-2'}}</td>
              <td>{{(specUserManagerData.jackpotCommission + specUserManagerData.houseCommission) | number:'1.2-2'}}</td>
              <td>{{(specUserManagerData.jackpotCommission) | number:'1.2-2'}}</td>
              <td>{{(specUserManagerData.houseCommission) | number:'1.2-2'}}</td>
              <td>{{(specUserManagerData.managerCommission) | number:'1.2-2'}}</td>
              <td>{{specUserManagerData.profitFromPlayers | number:'1.2-2' }}</td>
              <td>{{((specUserManagerData.profitFromPlayers) - specUserManagerData.managerCommission) | number:'1.2-2'}} ({{(specUserManagerData.managerCommission) | number:'1.2-2'}})</td>
              <td>{{(specUserManagerData.jackpotAmount + specUserManagerData.manualyAddedJackpotAmount) | number:'1.2-2'}}</td>
              <td>{{(specUserManagerData.manualyAddedJackpotAmount) | number:'1.2-2'}}</td>
              <td>{{specUserManagerData.profitFromPlayers - specUserManagerData.managerCommission - specUserManagerData.roulletteManagerCommission | number:'1.2-2'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" id="kt_modal_3">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><span class="h2 text-capitalize " *ngIf="specUserManagerData">{{specUserManagerData.username}}</span> <br>{{'ROULETTE_DETAILS' | translate}}</h5>
        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" id="closeModal2" #closeModal2 data-bs-dismiss="modal" aria-label="Close">
          <span class="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="1" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
              </svg>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-row-dashed table-striped table-row-gray-300 align-middle gy-4 gs-5">
            <thead>
            <tr>
              <th scope="col">{{'ROULLETTE_MANAGER_COMMS' | translate}}</th>
              <th scope="col">{{'TOTAL' | translate}}</th>
              <th scope="col">{{'ROULLETTE_SPENT' | translate}}</th>
              <th scope="col">{{'ROULLETTE_WIN' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="specUserManagerData">
              <td>{{(specUserManagerData.roulletteManagerCommission) | number:'1.2-2'}}</td>
              <td>{{(specUserManagerData.roulletteWin-specUserManagerData.roulletteSpent) | number:'1.2-2'}}</td>
              <td>{{(specUserManagerData.roulletteSpent) | number:'1.2-2'}}</td>
              <td>{{(specUserManagerData.roulletteWin) | number:'1.2-2'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="toolbar" id="kt_toolbar">
  <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
      <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{weekEnum[weekIndex]}}</h1>
      <span class="h-20px border-gray-200 border-start mx-4"></span>
      <!--end::Separator-->
      <!--begin::Breadcrumb-->
      <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'HOME' | translate}}</a>
        </li>
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li>
        <li class="breadcrumb-item text-dark">{{weekEnum[weekIndex]}}</li>
      </ul>
    </div>
  </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl container-full-width">
    <div class="card mb-5 mb-xl-8">
      <div class="card-body py-3">
        <div class="table-responsive">
          <table class="table table-row-dashed table-striped table-row-gray-300 align-middle gy-4 gs-5">
            <thead>
            <tr>
              <th scope="col">{{'ID' | translate}}</th>
              <th scope="col">{{'USER_NAME' | translate}}</th>
              <th scope="col">{{'RESET_PASSWORD' | translate}}</th>
              <th scope="col">{{'NR_PLAYERS' | translate}}</th>
              <th scope="col">{{'WEEKLY_START_A' | translate}}</th>
              <th scope="col">{{'NEXT_WEEKLY_START' | translate}}</th>
              <th scope="col">{{'PLAYERS_BALANCE(D)' | translate}}</th>
              <th>
                {{'POKER' | translate}}
              </th>
              <th>
                {{'ROULETE' | translate}}
              </th>
              <th scope="col">{{'WIN_LOST_FORMULA' | translate}}</th>
              <th scope="col">{{'MANAGER_COMMS' | translate}}</th>
              <th scope="col">{{'ROULLETTE_MANAGER_COMMS' | translate}} </th>
              <th scope="col">{{'TOTAL' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let manager of managerList">
              <td>{{manager.userId}}</td>
              <td>{{manager.username}}</td>
              <td>
                <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#kt_modal_1"
                        (click)="initPasswordForm(manager.userId)">
                  {{'CHANGE_PASSWORD' | translate}}
                </button>
              </td>
              <td>{{manager.numberOfUsers}}</td>
              <td class="d-flex justify-content-between align-items-center">
                <span class="me-3">{{manager.managerWeeklyStart | number:'1.2-2'}}</span>
                <button type="button" class="btn btn-primary btn-sm px-2" data-bs-toggle="modal" (click)="selectUser(manager)" data-bs-target="#kt_modal_4">
                  <svg style="margin-top: -3px; margin-right: -3px;" fill="none" height="18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m14 2 4 4L7 17H3v-4L14 2zM3 22h18"/></svg>
                </button>
              </td>
              <td>{{manager.nextWeeklyStart | number:'1.2-2'}}</td>
              <td>{{manager.playersBalance | number:'1.2-2'}}</td>
              <td>
                <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#kt_modal_2" (click)="selectSpecUser(manager)">
                  {{'POKER' | translate}}
                </button>
              </td>
              <td>
                <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#kt_modal_3" (click)="selectSpecUser(manager)">
                  {{'ROULETE' | translate}}
                </button>
              </td>
              <td>{{manager.profitFromPlayers | number:'1.2-2' }}</td>
              <td>{{(manager.managerCommission) | number:'1.2-2'}}</td>
              <td>{{(manager.roulletteManagerCommission) | number:'1.2-2'}}</td>
              <td>{{manager.profitFromPlayers - manager.managerCommission - manager.roulletteManagerCommission | number:'1.2-2'}}</td>
            </tr>
            </tbody>
            <tfoot *ngIf="totals">
            <tr>
              <td><strong>{{'TOTAL' | translate}}:</strong></td>
              <td></td>
              <td></td>
              <td>{{totals.numberOfUsers}}</td>
              <td>{{totals.managerWeeklyStart | number:'1.2-2'}}</td>
              <td></td>
              <td>{{totals.playersBalance | number:'1.2-2'}}</td>
              <td>{{(totals.regionManagerCommission || 0) | number:'1.2-2'}}</td>
              <td>{{(totals.rulletteRegionManagerCommission || 0) | number:'1.2-2'}}</td>
              <td>{{totals.profitFromPlayers | number:'1.2-2' }}</td>
              <td>{{(totals.managerCommission) | number:'1.2-2'}}</td>
              <td>{{(totals.roulletteManagerCommission) | number:'1.2-2'}}</td>
              <td>{{totals.profitFromPlayers - totals.managerCommission - totals.roulletteManagerCommission | number:'1.2-2'}}</td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [(pageSize)]='query.PageSize' [(page)]="query.PageNumber" (pageChange)="changePage($event)"></ngb-pagination>

  </div>
</div>

<div class="modal fade" tabindex="-1" id="kt_modal_4">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'EDIT_WEEKLY_START' | translate}}</h5>
        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" id="closeModal" #closeWeekModal data-bs-dismiss="modal" aria-label="Close">
          <span class="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="1" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
              </svg>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <div class="modal-body">
        <form class="card mb-5 mb-xl-8" [formGroup]="weeklyStartForm" (ngSubmit)="changeWeeklyStart()">
          <div class="card-body py-6">
            <div class="row mb-3">
              <div class="col-xs-12 col-md-4">
                <p class="mb-0 mt-4">{{'NEW_WEEKLY_START' | translate}}</p>
              </div>
              <div class="col-xs-12 col-md-8">
                <input type="number" class="form-control"  formControlName="weeklyStart" placeholder="{{'NEW_WEEKLY_START' | translate}}" autocomplete="off" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-xs-12 col-md-4">
                <p class="mb-0 mt-4">{{'NEW_PERCENT' | translate}}</p>
              </div>
              <div class="col-xs-12 col-md-8">
                <input type="number" class="form-control"  formControlName="percent" placeholder="{{'NEW_PERCENT' | translate}}" autocomplete="off" />
              </div>
            </div>

          </div>
          <div class="card-footer d-flex justify-content-between py-6">
            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-warning">{{'CANCEL' | translate}}</button>
            <button type="submit" class="btn btn-primary" [disabled]="weeklyStartForm.invalid">{{'CONFIRM' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


