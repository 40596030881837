import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RegionalManagerService} from '../../../../core/services/regional-manager/regional-manager.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {concatMap, tap} from 'rxjs/operators';
import {ManagerUserWeekQuery} from '../../../../core/models/ManagerUserWeekQuery';
import {DataService} from '../../../../core/services/data/data.service';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-transfer-to-poker-manager',
  templateUrl: './transfer-to-poker-manager.component.html',
  styleUrls: ['./transfer-to-poker-manager.component.scss']
})
export class TransferToPokerManagerComponent implements OnInit {
  public transferForm: FormGroup;
  public transferToManager = '1';
  public managerList = [];
  public user;
  public managerInfo;
  public weeks = [];
  public weekIndex = 0;
  public balance: number = null;
  public query = null;

  constructor(private regionalManagerService: RegionalManagerService,
              private translateService: TranslateService,
              private dataService: DataService,
              private store: Store,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.user = this.store.snapshot().eliteAuth.user;

    this.loadManager();
    this.createTransferForm();
    this.getWeeak();
  }

  createTransferForm(): void {
    this.transferForm = new FormGroup({
      toUserId: new FormControl('', Validators.required),
      amount: new FormControl('', Validators.required),
      otpNumber: new FormControl(1, Validators.required),
      description: new FormControl('', []),
    }, {
      updateOn: 'change'
    });

  }

  loadManager(): void{
    const data = {
      Role: 100,
      PageNumber: 1,
      PageSize: 10000
    };
    this.regionalManagerService.getMyManagers(data).subscribe(
      res => {
        this.managerList = res.result;
        if(res.result.length) {
          this.transferForm.controls['toUserId'].setValue(res.result[0].userId);
        }
      },
      err => console.log(err)
    );
  }

  getWeeak(): void {
    this.dataService.getWeeks().pipe(
      tap((res) => console.log(res)),
      tap(weeks => {
        this.weeks = weeks;
        return this.query = new ManagerUserWeekQuery(1, 10,  weeks[0].id, this.user.userId);
      }),
      concatMap(() => {
        return  this.regionalManagerService.getMyStatus(this.query); }
      )).subscribe(
      res => {
        this.managerInfo = res;
        this.balance = this.managerInfo?.managerCurrentBalance;
        console.log(res);
      },
      err => console.log(err)
    );
  }


  transfer(): void{
    console.log(this.transferForm.value);
    console.log(this.transferToManager);

    this.transferForm.markAllAsTouched();
    if (this.transferForm.invalid) { return; }

    if(this.transferToManager === '1') {
      this.transferTo(this.transferForm.value);
    }

    if(this.transferToManager === '0') {
      this.transferFrom(this.transferForm.value);
    }

  }


  transferTo(data): void {
    const transferInfo = {
      toUserId: data.toUserId,
      amount: data.amount,
      otpNumber: data.otpNumber,
      description: data.description,
    };

    this.regionalManagerService.transferToManager(transferInfo).subscribe(
      res => {
        this.createTransferForm();
        this.toastrService.success('Money successful transferred.');
        this.getWeeak();
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('Server not responding');
      }
    );
  }

  transferFrom(data): void {


    const transferInfo = {
      fromUserId: data.toUserId,
      amount: data.amount,
      otpNumber: data.otpNumber,
      description: data.description,
    };

    this.regionalManagerService.transferFromManager(transferInfo).subscribe(
      res => {
        this.createTransferForm();
        this.toastrService.success('Money successful transferred.');
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('Server not responding');
      }
    );
  }

  clear(): void {
    this.createTransferForm();
  }
}
