<div class="page d-flex flex-row flex-column-fluid">
   <div class="d-flex w-100 flex-center flex-column flex-column-fluid py-20 pb-lg-20">
      <div style="max-width: 95%;" class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
         <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="text-center mb-10">
               <img src="assets/media/logo.png" alt="" width="130">
               <br>
               <h1 class="text-dark mb-3">{{'SING_IN_POKER' | translate}}</h1>
            </div>
            <div class="fv-row mb-10">
               <label class="form-label fs-6 fw-bolder text-dark">{{'USER_NAME' | translate}} </label>
               <input class="form-control form-control-lg form-control-solid" formControlName="phoneNumber" type="text" name="phoneNumber" autocomplete="off" />
            </div>
            <div class="fv-row mb-10">
               <div class="d-flex flex-stack mb-2">
                  <label class="form-label fw-bolder text-dark fs-6 mb-0">{{'PASSWORD' | translate}}</label>
               </div>
               <input class="form-control form-control-lg form-control-solid" formControlName="password" type="password" name="password" autocomplete="off" />
            </div>
            <div class="text-center">
               <button type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 mb-5">
                  <span class="indicator-label" *ngIf="!loader">{{'CONTINUE' | translate}}</span>
                  <span class="indicator-label" *ngIf="loader">{{'PLEASE_WAIT' | translate}}</span>
                  <span class="spinner-border spinner-border-sm align-middle ms-2" *ngIf="loader"></span>
               </button>
            </div>
         </form>
      </div>
   </div>
</div>
