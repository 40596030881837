import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()

export class ServerStatusInterceptor implements HttpInterceptor {
constructor() {}
  intercept( req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(
          (event: any ) => {},
          error => {
            // console.log(error)
          if ((error.status === 500 || error.status === 0 ) ) {
              console.log('notify user about server error');
          }
        }));
  }
}
