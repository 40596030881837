<div class="page d-flex flex-row flex-column-fluid">
  <div class="d-flex flex-center flex-column flex-column-fluid p-20 pb-lg-20">
     <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
        <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="verifyForm" (ngSubmit)="verify()">
          <div class="text-center mb-10">
            <h1 class="text-dark mb-3">{{'ADMIN_PANEL' | translate}}</h1>
            <div class="text-gray-400 fw-bold fs-4">{{'OTP_AUTH' | translate}}</div>
          </div>
          <div class="row">
            <div class="col-2">
              <div class="fv-row mb-10">
                <input class="form-control form-control-lg form-control-solid" type="text" maxlength="1" OnlyNumber="true" [(ngModel)]="digits.digit1" [ngModelOptions]="{standalone: true}" appMoveToNext autocomplete="off">
              </div>
            </div>
            <div class="col-2">
              <div class="fv-row mb-10">
                <input class="form-control form-control-lg form-control-solid" type="text" maxlength="1" OnlyNumber="true" [(ngModel)]="digits.digit2" [ngModelOptions]="{standalone: true}" appMoveToNext autocomplete="off">
              </div>
            </div>
            <div class="col-2">
              <div class="fv-row mb-10">
                <input class="form-control form-control-lg form-control-solid" type="text" maxlength="1" OnlyNumber="true" [(ngModel)]="digits.digit3" [ngModelOptions]="{standalone: true}" appMoveToNext autocomplete="off">
              </div>
            </div>
            <div class="col-2">
              <div class="fv-row mb-10">
                <input class="form-control form-control-lg form-control-solid" type="text" maxlength="1" OnlyNumber="true" [(ngModel)]="digits.digit4" [ngModelOptions]="{standalone: true}" appMoveToNext autocomplete="off">
              </div>
            </div>
            <div class="col-2">
              <div class="fv-row mb-10">
                <input class="form-control form-control-lg form-control-solid" type="text" maxlength="1" OnlyNumber="true" [(ngModel)]="digits.digit5" [ngModelOptions]="{standalone: true}" appMoveToNext autocomplete="off">
              </div>
            </div>
            <div class="col-2">
              <div class="fv-row mb-10">
                <input class="form-control form-control-lg form-control-solid" type="text" maxlength="1" OnlyNumber="true" [(ngModel)]="digits.digit6" [ngModelOptions]="{standalone: true}" appMoveToNext autocomplete="off">
              </div>
            </div>
          </div>
          <div class="text-center">
            <button type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 mb-5">{{'CONFIRM' | translate}}</button>
          </div>
        </form>
     </div>
  </div>
</div>

