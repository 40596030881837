import { Component, OnInit } from '@angular/core';
import {TransactionTypeEnum} from '../../../../../core/enum/TransactionTypeEnum';
import {GameTypeEnum} from '../../../../../core/enum/GameTypeEnum';
import {SearchForUserById, SearchTransactionsForUser} from '../../../../../core/models/ManagerUserWeekQuery';
import {AdminService} from '../../../../../core/services/admin/admin.service';
import {ActivatedRoute} from '@angular/router';
import {RoleEnum} from '../../../../../core/enum/Role';

@Component({
  selector: 'app-show-user-details',
  templateUrl: './show-user-details.component.html',
  styleUrls: ['./show-user-details.component.scss']
})
export class ShowUserDetailsComponent implements OnInit {
  // getUserById

  public user = null;
  public userId = null;
  public transactionTypeEnum = TransactionTypeEnum;
  public gameTypeEnum = GameTypeEnum;
  public collectionSize = null;
  public query;
  public roleEnum = RoleEnum;

  constructor(private adminService: AdminService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    const {id} = this.route.snapshot.params;
    if (id) {
      this.userId = id;
      this.query = new SearchForUserById(1, 100, id);
      this.search();
    }
  }

  search(): void {
    this.adminService.getUserById(this.query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.user = res.result[0];
      },
      err => console.log(err)
    );
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.search();
  }

}
