import { AuthService } from './../core/services/auth/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {AuthState} from '../store/auth/auth.state';
import {Store} from '@ngxs/store';
import { Logout } from '../store/auth/auth.actions';
import {DataService} from "../core/services/data/data.service";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public subNavNumb: number = null;
  public userRole$ = this.store.select(AuthState.getUserRole);
  public adminName: string = null;
  public toggleAside = false;
  public toggleAsideMob = false;
  public weeakNumber: number = null;
  public headerDropdown = false;

  constructor(private store: Store,
              private authService: AuthService,
              private dataService: DataService,
              private translateService: TranslateService,
              private router: Router) { }

  ngOnInit(): void {
    this.adminName = this.store.snapshot().eliteAuth.user.userName;
    this.adminName = this.adminName.charAt(0).toUpperCase() + this.adminName.substr(1);


    this.dataService.getWeeks().subscribe(
      res => {
        this.weeakNumber = res.length;
        console.log('Weeak', this.weeakNumber);
      },
      err =>  {
        console.log('Weeak', err);
      }
    );
  }

  triggerSubMenu(numb:any) {

    console.log(this.subNavNumb)

    if(this.subNavNumb) {
      if( this.subNavNumb != numb) {
        this.subNavNumb = numb
      }else {
        this.subNavNumb = null;
      }

    }else {
      this.subNavNumb = numb;
    }
  }

  selectLanguage(value): void {
    this.translateService.setDefaultLang(value);
  }

  logout(): void {
    console.log('klik na logout')
    this.authService.logout().subscribe(
      res => console.log(res),
      err => console.log(err)
    )
    this.store.dispatch(new Logout());
    this.router.navigate(['/login']);
  }

}
