import { Component, OnInit } from '@angular/core';
import {RoleEnum} from '../../../../../core/enum/Role';
import {SearchLog, SearchPokerGamesForUser} from '../../../../../core/models/ManagerUserWeekQuery';
import {AdminService} from '../../../../../core/services/admin/admin.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-poker-game-log',
  templateUrl: './poker-game-log.component.html',
  styleUrls: ['./poker-game-log.component.scss']
})
export class PokerGameLogComponent implements OnInit {
  public logList = [];
  public userId = null;
  public collectionSize = null;
  public roles = RoleEnum;
  public query = new SearchLog();

  constructor(private adminService: AdminService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    const {gameNumber, gameKey} = this.route.snapshot.params;
    console.log(gameNumber)
    console.log(gameKey)
    if (gameNumber && gameKey) {
      this.query.GameNumber = gameNumber;
      this.query.GameKey = gameKey;
      this.search()
    }
  }

  search(): void {
    this.adminService.getGameLog(this.query).subscribe(
      res => {
        this.logList = res;
      },
      err => console.log(err)
    )
  }
}
