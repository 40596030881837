// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverURL: 'https://auth.elite.bets4us.com',
  serverPokerURL: 'https://poker.elite.bets4us.com',
  serverAccountsURL: 'https://account.elite.bets4us.com',
  virtualGame: 'https://virtualgameapi.elitespoker.com',
  errorURL: 'https://errors.elite.bets4us.com',
  // serverURL: 'https://authapi.elitespoker.com',
  // serverPokerURL: 'https://pokerapi.elitespoker.com',
  // serverAccountsURL: 'https://accountsapi.elitespoker.com',
  // virtualGame: 'https://virtualgameapi.elitespoker.com',
  // errorURL: 'https://errors.elitespoker.com',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
