<div class="toolbar" id="kt_toolbar">
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
      <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
        <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{weekEnum[weekIndex]}}</h1>
        <span class="h-20px border-gray-200 border-start mx-4"></span>
        <!--end::Separator-->
        <!--begin::Breadcrumb-->
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">
            <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'HOME' | translate}}</a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <!-- <li class="breadcrumb-item text-muted">Widgets</li> -->
          <!--end::Item-->
          <!--begin::Item-->
          <!-- <li class="breadcrumb-item">
              <span class="bullet bg-gray-200 w-5px h-2px"></span>
          </li> -->
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item text-dark">{{weekEnum[weekIndex]}}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl container-full-width">
      <div class="card mb-5 mb-xl-8">
        <div class="card-body py-3">
          <div class="table-responsive">
            <table class="table table-row-dashed table-striped table-row-gray-300 align-middle gy-4 gs-5">
              <thead>
              <tr>
                <th scope="col">{{'NICKNAME' | translate}}</th>
                <th scope="col">{{'BALANCE' | translate}}</th>
                <th scope="col">{{'ADDED_BALANCE' | translate}}</th>
                <th scope="col">{{'PROFIT' | translate}}</th>
                <th scope="col">{{'START' | translate}}</th>
                <th scope="col">{{'ID' | translate}}</th>
                <th scope="col">{{'STATUS' | translate}}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let user of userList">
                <td>{{user.username}}</td>
                <td>{{user.playersBalance  | number:'1.2-2'}}</td>
                <td>{{user.pointsAdded  | number:'1.2-2'}}</td>
                <td>{{(user.playersBalance - user.weeklyStart - user.pointsAdded) | number:'1.2-2' }}</td>
                <td class="text-nowrap">{{user.weeklyStart  | number:'1.2-2'}}
                </td>
                <td>{{user.userId}}</td>
                <td>
                  <div class="form-check form-switch form-check-custom form-check-solid">
                    <input class="form-check-input" type="checkbox" (click)="changeStatus(user)" [(ngModel)]="!user.status" id="flexSwitchDefault" autocomplete="off" />
                    <label class="form-check-label" for="flexSwitchDefault">
                      {{'OFF_ON' | translate}}
                    </label>
                  </div>
                </td>
              </tr>
              </tbody>
              <tfoot *ngIf="userList.length">
              <tr>
                <td><strong>{{'TOTAL' | translate}}:</strong></td>
                <td>{{totals.playersBalance | number:'1.2-2'}}</td>
                <td>{{totals.pointsAdded | number:'1.2-2'}}</td>
                <td>{{(totals.playersBalance - totals.weeklyStart - totals.pointsAdded ) | number:'1.2-2'}}</td>
                <td>{{totals.weeklyStart  | number:'1.2-2' }}</td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <ngb-pagination [collectionSize]="collectionSize" [(pageSize)]='query.PageSize' [(page)]="query.PageNumber" (pageChange)="changePage($event)"></ngb-pagination>
    </div>
  </div>




