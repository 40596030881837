import { Component, OnInit } from '@angular/core';
import {VerifyOtp} from '../../../store/auth/auth.actions';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngxs/store';
import {AuthService} from '../../../core/services/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {VerifyModel} from '../../../core/models';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  public digits = new VerifyModel();
  public verifyForm: FormGroup;

  constructor(private store: Store,
              private authService: AuthService,
              private toastrService: ToastrService,
              private router: Router) {
    this.verifyForm = new FormGroup({
      otp: new FormControl( '', [Validators.required]),
    });
  }
  ngOnInit(): void {

  }

  verify(): void {

    const otpNumber = this.digits.mergeDigits();

    if (otpNumber.length !== 6) {
      return;
    }

    const authState = this.store.snapshot().eliteAuth;
    const optData = {
      userId: authState.user.userId,
      deviceId: authState.deviceId,
      logId: authState.user.logId,
      otpNumber: Number(otpNumber)
    };

    console.log(optData);
    const userRole = this.store.snapshot().eliteAuth.user.role;


    this.authService.verifyOtp(optData).subscribe(
      async res => {
        this.store.dispatch(new VerifyOtp(res));
        switch (res.user.role) {
          case 2:
            await this.router.navigate(['admin/user-weekly-status/0']);
            break;
          case 100:
            await this.router.navigate(['admin/user-weekly-status/0']);
            break;
          case 101:
            await this.router.navigate(['regional/manager-weekly-status/0']);
            break;
          default:
            console.log(`Sorry.`);
        }      },
      err => {
        console.log(err);
        this.toastrService.success(err.error.systemMessage);
      }
    );
  }

}
