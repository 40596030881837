
export class Login {
  static readonly type = '[Auth] Login';
  constructor(public data: any) {}
}

export class VerifyOtp {
  static readonly type = '[Auth] VerifyOtp';
  constructor(public data: any) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
  constructor() {}
}

export class RememberMe {
  static readonly type = '[Auth] RememberMe';
  constructor(public data: {UserName: string}) {}
}

export class SetLanguage {
  static readonly type = '[Auth] SetLanguage';
  constructor(public language: string) {}
}

export class ClearRememberMe {
  static readonly type = '[Auth] ClearRememberMe';
  constructor() {}
}

export class SetUserKey {
  static readonly type = '[Auth] SetUserKey';
  constructor(public userKey: string) {}
}
