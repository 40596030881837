import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {ToastrService} from 'ngx-toastr';
import {JackpotQuery} from '../../../../core/models/ManagerUserWeekQuery';

@Component({
  selector: 'app-jackpot-list',
  templateUrl: './jackpot-list.component.html',
  styleUrls: ['./jackpot-list.component.scss']
})
export class JackpotListComponent implements OnInit {
  jackpotList: any[] = [];
  public changePasswordForm: FormGroup;
  public collectionSize = null;
  public selectedUser = null;

  public query = new JackpotQuery(1, 10);

  constructor(private adminService: AdminService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.getJackpotList(this.query);
  }

  getJackpotList(query): void {
    this.adminService.getAllJackpotList(query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.jackpotList = res.result;
      },
      err => console.log(err),
    )
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getJackpotList(this.query);
  }


}
