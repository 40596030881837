import { Component, OnInit } from '@angular/core';
import {TransactionTypeEnum} from '../../../../../core/enum/TransactionTypeEnum';
import {GameTypeEnum} from '../../../../../core/enum/GameTypeEnum';
import {RoleEnum} from '../../../../../core/enum/Role';
import {SearchTransactionsForUser} from '../../../../../core/models/ManagerUserWeekQuery';
import {AdminService} from '../../../../../core/services/admin/admin.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit {

  public transaction = null;
  public userId = null;
  public transactionTypeEnum = TransactionTypeEnum;
  public gameTypeEnum = GameTypeEnum;
  public collectionSize = null;
  public query = new SearchTransactionsForUser(1, 10, '');

  constructor(private adminService: AdminService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    const {id, transactionId} = this.route.snapshot.params;
    if (id) {
      this.userId = id;
      this.query.TransactionId = transactionId || '';
      this.search();
    }
  }

  search(): void {
    this.adminService.getUserTransactionsById(this.query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.transaction = res.result[0];
      },
      err => console.log(err)
    );
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.search();
  }
}
