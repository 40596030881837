import {Route} from '@angular/router';
import {AuthModule} from './pages/auth/auth.module';
import {LayoutComponent} from './layout/layout.component';
import {DashboardModule} from './pages/dashboard/dashboard.module';

export const routes: Route[] = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => AuthModule
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => DashboardModule
      },
      {
        path: 'transfer',
        loadChildren: () => DashboardModule
      },
      {
        path: 'create-manager',
        loadChildren: () => DashboardModule
      },
      {
        path: 'manager-list',
        loadChildren: () => DashboardModule
      },
      {
        path: 'manager-users-list',
        loadChildren: () => DashboardModule
      },
      {
        path: 'active-tables',
        loadChildren: () => DashboardModule
      },
      {
        path: 'managers-current-week-status',
        loadChildren: () => DashboardModule
      },
      {
        path: 'managers-last-week-status',
        loadChildren: () => DashboardModule
      },
      {
        path: 'player-list',
        loadChildren: () => DashboardModule
      },
      {
        path: 'main-screen',
        loadChildren: () => DashboardModule
      },
      {
        path: 'create-player',
        loadChildren: () => DashboardModule
      },
      {
        path: 'current-week',
        loadChildren: () => DashboardModule
      },
      {
        path: 'globa-messages',
        loadChildren: () => DashboardModule
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'login'
  }
]
