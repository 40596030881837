import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Observable} from "rxjs";
import {Store} from "@ngxs/store";
import {Logout} from '../../store/auth/auth.actions';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor( private router: Router,
               private ngZone: NgZone,
               private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        tap(event => {},
            error => {
          if(error.status === 401){
            this.store.dispatch(new Logout());
            this.ngZone.run(() => this.router.navigate(['/login']));
          }
        })
      );
  };

}
