import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-user-balance-correction',
  templateUrl: './user-balance-correction.component.html',
  styleUrls: ['./user-balance-correction.component.scss']
})
export class UserBalanceCorrectionComponent implements OnInit {
  public managerForm: FormGroup;
  public userList: any[] = [];
  public query = {
    Role: 0,
    PageNumber: 1,
    PageSize: 10000
  };

  public refreshSelect = true;

  constructor(private adminService: AdminService,
              private translateService: TranslateService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.correctionForm();
    this.getPlayers(this.query)

  }

  correctionForm(): void {
    this.managerForm = new FormGroup({
      UserId : new FormControl('', Validators.required),
      Amount : new FormControl(0, Validators.required),
      Description : new FormControl('Correction by admin', Validators.required),
      CurrencyId : new FormControl(1, Validators.required)
    }, {
      updateOn: 'change'
    });
    if (this.userList.length > 0) {
      this.managerForm.controls['UserId'].setValue(this.userList[0].userId);

    }


  }

  getPlayers(query): void {
    this.adminService.getAllManager(query).subscribe(
      res => {
        console.log(res);
        this.userList = res.result;
        if(this.userList.length > 0) {
          this.managerForm.controls['UserId'].setValue(this.userList[0].userId);
        }
      },
      err => console.log(err),
    )
  }

  create(): void{

    this.managerForm.markAllAsTouched();
    if (this.managerForm.invalid) { return; }
    this.refreshSelect = false;
    this.adminService.manualCorrection(this.managerForm.value).subscribe(
      res => {
        this.refreshSelect = true;

        console.log(res);
        this.correctionForm();
        this.toastrService.success('Correction successful done.');
      },
      async err => {
        this.refreshSelect = true;
        if (this.userList.length > 0) {
          this.managerForm.controls['UserId'].setValue(this.userList[0].userId);

        }

        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('We failed to do correction.');
      }
    );
  }

  clear(): void {
    this.correctionForm();

  }

}
