<div class="modal fade" tabindex="-1" id="kt_modal_1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'RESET_PASSWORD' | translate}}</h5>

        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" id="closeModal" #closeModal data-bs-dismiss="modal" aria-label="Close">
                    <span class="svg-icon svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="1" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                        </svg>
                    </span>
        </div>
        <!--end::Close-->
      </div>
      <div class="modal-body">
        <form class="card mb-5 mb-xl-8" [formGroup]="changePasswordForm" (submit)="change()">
          <div class="card-body py-6">
            <div class="row mb-3">
              <div class="col-xs-12 col-md-4">
                <p class="mb-0 mt-4">{{'PASSWORD' | translate}}</p>
              </div>
              <div class="col-xs-12 col-md-8">
                <input type="password" class="form-control"  formControlName="newPassword" placeholder="Password" autocomplete="off" />
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between py-6">
            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-warning">{{'CANCEL' | translate}}</button>
            <button type="submit" class="btn btn-primary" [disabled]="changePasswordForm.invalid">{{'CONFIRM' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" tabindex="-2" id="deleteUser">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'DELETE_MANAGER' | translate}}</h5>
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" id="deleteUser" #deleteUser data-bs-dismiss="modal" aria-label="Close">
          <span class="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="1" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
              </svg>
          </span>
        </div>
      </div>
      <div class="modal-body">
        <form class="card mb-5 mb-xl-8">
          <div class="card-body py-6">
            <p>{{'DELETE_MANAGER_QUESTION' | translate}}</p>
          </div>
          <div class="card-footer d-flex justify-content-between py-6">
            <button data-bs-dismiss="modal" aria-label="Close" class="btn btn-warning">{{'CANCEL' | translate}}</button>
            <button class="btn btn-primary" (click)="deletePlayer()">{{'CONFIRM' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="toolbar" id="kt_toolbar">
  <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
      <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{'REGIONAL_MANAGER_LIST' | translate}}</h1>
      <span class="h-20px border-gray-200 border-start mx-4"></span>

      <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'HOME' | translate}}</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="breadcrumb-item text-muted">Widgets</li> -->
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li> -->
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-dark">{{'REGIONAL_MANAGER_LIST' | translate}}</li>
        <!--end::Item-->
      </ul>
    </div>
  </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl container-full-width">
    <div class="card mb-5 mb-xl-8">

      <div class="card-body py-3">
        <div class="table-responsive">
          <table class="table table-row-dashed table-striped table-row-gray-300 align-middle gy-4 gs-5">
            <thead>
            <tr>
              <th scope="col">{{'ID' | translate}}</th>
              <th scope="col">{{'USER_NAME' | translate}}</th>
              <th scope="col">{{'NICKNAME' | translate}}</th>
              <th scope="col">{{'RESET_PASSWORD' | translate}}</th>
              <th scope="col">{{'DELETE' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let manager of managerList">
              <td>{{manager.userId}}</td>
              <td>{{manager.userName}}</td>
              <td>{{manager.fullName}}</td>
              <td>
                <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#kt_modal_1"
                        (click)="initPasswordForm(manager.userId)">
                        {{'RESET' | translate}}
                </button>
              </td>
              <td>
                <button type="button" (click)="deletePlayerModal(manager.userId)" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#deleteUser">
                  {{'DELETE' | translate}}
                </button>
              </td>
              <td>
                <div class="form-check form-switch form-check-custom form-check-solid">
                  <input class="form-check-input" style="transition: unset !important;" type="checkbox" (click)="changeStatus(manager)" [(ngModel)]="!manager.status" id="flexSwitchDefault" autocomplete="off" />
                  <label class="form-check-label" for="flexSwitchDefault">
                    {{'OFF_ON' | translate}}
                  </label>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>






