<div class="toolbar" id="kt_toolbar">
  <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
      <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{'TRANSFER' | translate}}</h1>
      <span class="h-20px border-gray-200 border-start mx-4"></span>

      <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
        <li class="breadcrumb-item text-muted">
          <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'HOME' | translate}}</a>
        </li>
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li>
        <li class="breadcrumb-item text-dark">{{'TRANSFER' | translate}}</li>
      </ul>
    </div>
  </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
  <form id="kt_content_container" class="container-xxl" [formGroup]="transferForm" (submit)="transfer()">
    <div class="card mb-5 mb-xl-8">
      <div class="card-body py-6">
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'MANAGER_BALANCE' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="row mt-3 mb-3">
              <div class="col-xs-12 col-md-12">
                <input type="text" value="{{balance}}" class="form-control" [disabled]="true" autocomplete="off" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'CHOSE_REGIONAL_MANAGER' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <ng-select (change)="getManagers()" [ngModelOptions]="{standalone: true}" [(ngModel)]="pokerManagerQuery.ManagerId">
              <ng-option *ngFor="let manager of regionalManagerList" [value]="manager.userId">{{manager.userName}}</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'CHOOSE_POKER_MANAGER' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <ng-select formControlName="toUserId">
              <ng-option *ngFor="let manager of pokerManagerList" [value]="manager.userId">{{manager.userName}}</ng-option>
            </ng-select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xs-12 col-md-4"></div>
          <div class="col-xs-12 col-md-8">
            <div class="row mt-3 mb-3">
              <div class="col-xs-12 col-md-6">
                <div class="form-check form-check-custom form-check-solid mb-2">
                  <input class="form-check-input" type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="transferToManager" value="1" name="transfer_manager" id="flexRadioChecked" checked="checked" autocomplete="off" />
                  <label class="form-check-label" for="flexRadioChecked">
                    {{'TRANSFER_TO_MANAGER' | translate}}
                  </label>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <div class="form-check form-check-custom form-check-solid">
                  <input class="form-check-input" type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="transferToManager" value="0" name="transfer_manager" id="flexRadioChecked1" checked="checked" autocomplete="off" />
                  <label class="form-check-label" for="flexRadioChecked1">
                    {{'TRANSFER_FROM_MANAGER' | translate}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'AMOUNT' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <input type="text" formControlName="amount" class="form-control" placeholder="Amount" autocomplete="off" />
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-between py-6">
        <button type="button" class="btn btn-warning">{{'CANCEL' | translate}}</button>
        <button  type="submit" class="btn btn-primary" [disabled]="transferForm.invalid">{{'CONFIRM' | translate}}</button>
      </div>
    </div>
  </form>
</div>





