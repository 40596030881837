import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import {Route, RouterModule} from '@angular/router';
import {ShareModule} from '../../share/share.module';
import { OtpComponent } from './otp/otp.component';
import {TranslateModule} from '@ngx-translate/core';

export const routes: Route[] = [
  { path: 'login', component: LoginComponent},
  {
    path: 'otp',
    component: OtpComponent
  },
];

@NgModule({
  declarations: [LoginComponent, OtpComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ShareModule,
        TranslateModule
    ],
  exports: [RouterModule]
})
export class AuthModule { }
