<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl">
    <div class="card mb-5 mb-xl-8">
      <div class="card-header align-items-center">
        <a routerLink="/admin/user-log" class="btn btn-warning btn-sm d-inline-flex align-items-center">
          <svg viewBox="0 0 1792 1792" class="me-1" width="15" height="15" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M1203 544q0 13-10 23L800 960l393 393q10 10 10 23t-10 23l-50 50q-10 10-23 10t-23-10L631 983q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23z"/></svg>
          {{'BACK' | translate}}
        </a>
      </div>
      <div class="card-body py-6">
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'ACCOUNT_NUMBER' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{user?.accountNumber}}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'USER_NAME' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{user?.phoneNumber}}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'PERCENT' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{user?.percent}}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'BIRTH_DATE' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{user?.birthDate | date: 'short' : 'local'}}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'COUNTRY' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{user?.country}}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'ROLE' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{roleEnum[user.role]}}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'WEEKLY_START' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{user?.weeklyStart}}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'TOTAL_MAIN_BALANCE' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{user?.totalMainBalance}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
