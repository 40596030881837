export class VerifyModel {
  public digit1: string = '';
  public digit2: string = '';
  public digit3: string = '';
  public digit4: string = '';
  public digit5: string = '';
  public digit6: string = '';

  public mergeDigits(): string {
    return this.digit1 + this.digit2 + this.digit3 + this.digit4 + this.digit5 + this.digit6;
  }
}
