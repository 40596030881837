<div class="toolbar" id="kt_toolbar">
  <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
      <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{'CREATE_MANAGER' | translate}}</h1>
      <span class="h-20px border-gray-200 border-start mx-4"></span>

      <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'Home' | translate}}</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="breadcrumb-item text-muted">Widgets</li> -->
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li> -->
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-dark">{{'CREATE_MANAGER' | translate}}</li>
        <!--end::Item-->
      </ul>
    </div>
  </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl">
    <form class="card mb-5 mb-xl-8" [formGroup]="managerForm" (submit)="create()">
      <div class="card-body py-6">

        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4"><b> {{'POINTS_AVAILABLE_NEXT_WEEK' | translate}}</b></p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="row mt-3 mb-3">
              <div class="col-xs-12 col-md-12">
                <input type="text" value="{{balance}}" class="form-control" [disabled]="true" autocomplete="off" />
              </div>
            </div>
          </div>
        </div>
        <hr>

        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'USER_NAME' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <input type="text" class="form-control" formControlName="phoneNumber" placeholder="Username" autocomplete="off" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'PASSWORD' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <input type="password" class="form-control"  formControlName="password" placeholder="Password" autocomplete="off" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'CONFIRM_PASSWORD' | translate}} </p>
          </div>
          <div class="col-xs-12 col-md-8">
            <input type="password" class="form-control" formControlName="passwordConf" placeholder="Confirm Password" autocomplete="off" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'WEEKLY_START' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <input type="number" class="form-control"  formControlName="weeklyStart" autocomplete="off" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'PERCENT' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <input type="number" class="form-control"  formControlName="percent" autocomplete="off" />
          </div>
        </div>

      </div>
      <div class="card-footer d-flex justify-content-between py-6">
        <button type="button" (click)="clear()" class="btn btn-warning">{{'CANCEL' | translate}}</button>
        <button type="submit" class="btn btn-primary" [disabled]="managerForm.invalid">{{'CONFIRM' | translate}}</button>
      </div>
    </form>
  </div>
</div>





