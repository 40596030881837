import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../core/services/manager/manager.service';
import {ManagerPlayerQuery, ManagerUserWeekQuery} from '../../../../core/models/ManagerUserWeekQuery';
import {Store} from '@ngxs/store';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '../../../../core/services/data/data.service';

@Component({
  selector: 'app-all-manager-player',
  templateUrl: './all-manager-player.component.html',
  styleUrls: ['./all-manager-player.component.scss']
})
export class AllManagerPlayerComponent implements OnInit {
  userList: any[] = [];
  public managerList = [];
  public changePasswordForm: FormGroup;
  public collectionSize = null;
  public selectedUser = null;
  public query = new ManagerPlayerQuery(1, 10);
  @ViewChild('closeModal') closeModal;
  @ViewChild('deleteUser') deleteUser;


  constructor(private managerService: ManagerService,
              private toastrService: ToastrService,
              private dataService: DataService,
              private store: Store) { }

  ngOnInit(): void {
    this.query.HideDeleted = true;
    this.query.ManagerId = this.store.snapshot().eliteAuth.user.userId;
    this.initPasswordForm(null);
    this.getPlayers(this.query);
  }

  getPlayers(query): void {
    this.managerService.getManagerPlayers(query).subscribe(
      res => {
        console.log(res);
        this.collectionSize = res.count;
        this.userList = res.result;
      },
      err => console.log(err),
    );
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getPlayers(this.query);
  }


  initPasswordForm(id): void {
    this.changePasswordForm = new FormGroup({
      userId: new FormControl(id, Validators.required),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, {
      updateOn: 'change'
    });

  }

  change(): void {
    console.log(this.changePasswordForm.value)
    this.managerService.changePlayerPassword(this.changePasswordForm.value).subscribe(
      res => {
        console.log(res);
        this.closeModal.nativeElement.click();
        this.toastrService.success('The password is a change');
      },
      err => console.log(err)
    );
  }

  deletePlayerModal(player): void {
    this.selectedUser = player;
  }

  deletePlayer(): void {
    this.managerService.deleteUser(this.selectedUser).subscribe(
      res => {
        console.log(res);
        this.getPlayers(this.query);
        this.deleteUser.nativeElement.click();
        this.toastrService.success('Player is Deleted');
      },
      err => {
        console.log(err);
        this.deleteUser.nativeElement.click();
      }
    );
  }

}
