import {Component, OnInit, ViewChild} from '@angular/core';
import {WeekEnum} from '../../../../../core/enum/WeekEnum';
import {ManagerUserWeekQuery, ManagerWeekQuery} from '../../../../../core/models/ManagerUserWeekQuery';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../../core/services/manager/manager.service';
import {AdminService} from '../../../../../core/services/admin/admin.service';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../../../core/services/data/data.service';
import {concatMap, tap} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";


@Component({
  selector: 'app-admin-roulette-managers-weekly',
  templateUrl: './admin-roulette-managers-weekly.component.html',
  styleUrls: ['./admin-roulette-managers-weekly.component.scss']
})
export class AdminRouletteManagersWeeklyComponent implements OnInit {
  public weekEnum = WeekEnum;
  public weekIndex;
  public query: ManagerUserWeekQuery = new ManagerUserWeekQuery(1, 1000);
  public managerList = [];
  public totals = null;
  public collectionSize = null;
  public changePasswordForm: FormGroup;
  @ViewChild('closeModal') closeModal;
  constructor(private managerService: ManagerService,
              private adminService: AdminService,
              private route: ActivatedRoute,
              private toastrService: ToastrService,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.initPasswordForm(null)

    this.route.params.subscribe((params) => {
      this.weekIndex = params.id;
      console.log('paramsss', params)
      this.dataService.getWeeks().pipe(
        tap((res) => console.log(res)),
        concatMap(weeks => {
          this.query = new ManagerWeekQuery(1, 10,  weeks[params.id].id);
          return  this.managerService.getManagerWeek(this.query)}
        )).subscribe(
        res => {
          this.collectionSize = res.count;
          this.totals = res.totals;
          this.managerList = res.result;
          console.log(res);
        },
        err => console.log(err)
      )
    })

  }

  initPasswordForm(id): void {

    console.log('init pass')
    this.changePasswordForm = new FormGroup({
      userId: new FormControl(id, Validators.required),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, {
      updateOn: 'change'
    });

  }


  change(){
    console.log(this.changePasswordForm.value)
    this.adminService.changePassword(this.changePasswordForm.value).subscribe(
      res => {
        console.log(res);
        this.closeModal.nativeElement.click();
        this.toastrService.success('The password is a change');
      },
      err => console.log(err)
    )
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getManagerWeek();
  }

  getManagerWeek(): void{
    this.adminService.getRegionalManagerWeek(this.query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.managerList = res.result;
        this.totals = res.totals;
      },
      err => console.log(err)
    )
  }

}
