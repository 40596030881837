export class ManagerUserWeekQuery {
  constructor(public PageNumber: number, public PageSize: number, public WeekNumber?: any, public ManagerId?: number) {}
}

export class ManagerWeekQuery {
  constructor(public PageNumber: number, public PageSize: number, public WeekNumber?: any) {}
}

export class ManagerPlayerQuery {
  public HideDeleted = false;
  constructor(public PageNumber: number, public PageSize: number, public ManagerId?: any) {}
}

export class RegionalManagerWeekQuery {
  public HideDeleted = false;

  constructor(public PageNumber: number, public PageSize: number, public WeekNumber?: any) {}
}
export class RegionalManagerQuery {
  constructor(public PageNumber: number, public PageSize: number, public Role?: any, public ManagerId?: any ) {}
}

export class GetUsersByRoleAdmin {
  public ManagerId;
    constructor(public PageNumber: number, public PageSize: number, public Role: any, ManagerId?) {
      if ( ManagerId ) this.ManagerId = ManagerId;
    }
}

export class SearchForUser {
  constructor(public PageNumber: number, public PageSize: number, public SearchTerm: any) {}
}


export class JackpotQuery {
  constructor(public PageNumber: number, public PageSize: number) {}
}

export class SearchPokerGamesForUser {
  public From: any = '';
  public To: any = '';
  public GameKey: any = '';
  public JustWinning: any = false;
  constructor(public PageNumber: number, public PageSize: number, public UserId: any) {}
}

export class SearchTransactionsForUser {
  public From: any = '';
  public To: any = '';
  public Type: any = '';
  public Description: any = '';
  public UserId: any = null;
  constructor(public PageNumber: number, public PageSize: number, public TransactionId: any) {}
}

export class SearchForUserById {
  constructor(public PageNumber: number, public PageSize: number,public UserId: any) {}
}

export class SearchLog {
  public GameNumber: any = '';
  public GameKey: any = '';
  constructor() {}
}

export class SearchGameRounds {
  public GameId = 1;
  public GameName = 'GAME_CATEGORY_VIRTUAL';
  constructor(public PageNumber: number, public PageSize: number, public Date: string) {}
}

