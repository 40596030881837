import { Component, OnInit } from '@angular/core';
import {ErrorLogService} from "../../../../core/services/error-log/error-log.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit {
  public errorsList = null;
  public params = {
    AppName: 'Elites Poker',
    Time: this.datePipe.transform( new Date(), 'yyyy-MM-dd'),
  };
  constructor(private errorLogService: ErrorLogService,
              private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getAllError(this.params);
  }

  getAllError(param): void {
    this.errorLogService.getErrors(param).subscribe(
      res => {
        this.errorsList = res;
        console.log(res);
      },
      error => {
        console.log(error);
      }
    );
  }
}
