import {Component, OnInit, ViewChild} from '@angular/core';
import {WeekEnum} from '../../../../core/enum/WeekEnum';
import {RegionalManagerQuery, RegionalManagerWeekQuery} from '../../../../core/models/ManagerUserWeekQuery';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RegionalManagerService} from '../../../../core/services/regional-manager/regional-manager.service';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngxs/store';
import {DataService} from '../../../../core/services/data/data.service';
import {concatMap, tap} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-my-managers-users',
  templateUrl: './my-managers-users.component.html',
  styleUrls: ['./my-managers-users.component.scss']
})
export class MyManagersUsersComponent implements OnInit {
  @ViewChild('closeWeekModal') closeWeekModal;
  public selectedUser = null;
  public weekEnum = WeekEnum;
  public weekIndex;
  public query: RegionalManagerQuery = new RegionalManagerQuery(1, 10, null);
  public myMangerList = [];
  public selectedManager = null;
  public weeklyStartForm;
  public userList = [];
  public totals = null;
  public collectionSize = null;
  public specUserManagerData = null;
  public changePasswordForm: FormGroup;
  @ViewChild('closeModal') closeModal;

  constructor(private regionalManagerService: RegionalManagerService,
              private adminService: AdminService,
              private route: ActivatedRoute,
              private store: Store,
              private translateService: TranslateService,
              private toastrService: ToastrService,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.initPasswordForm(null);
    this.createUpdateWeeklyStartForm(null);
    // const myId = this.store.snapshot().eliteAuth.user.userId;

    this.route.params.subscribe((params) => {
      this.weekIndex = params.id;
      this.dataService.getWeeks().pipe(
        tap((res) => console.log(res)),
        concatMap(weeks => {
          this.query = new RegionalManagerQuery(1, 10,  weeks[params.id].id);
          const data = {
            Role: 100,
            PageNumber: 1,
            PageSize: 1000
          };
          return this.regionalManagerService.getMyManagers(data);
        }),
        concatMap(managersData => {

          this.query.ManagerId = managersData.result[0].userId || 0;
          delete this.query.Role;
          this.myMangerList = managersData.result;
          return  this.regionalManagerService.getMyUsers(this.query); }
        )).subscribe(
        res => {
          this.userList = res.result;
          this.collectionSize = res.count;
          this.totals = res.totals;
          console.log(res);
        },
        err => console.log(err)
      );
    });
  }


  changeStatus({userId, status}): void {
    if(!status) {
      this.regionalManagerService.blockUser(userId, userId).subscribe(
        res => {
          this.toastrService.success('User is blocked');
          console.log(res)
        },
        err => console.log(err),
      );
    }

    if(status) {
      this.regionalManagerService.unblockUser(userId, userId).subscribe(
        res => {
          this.toastrService.success('User is unblocked');
          console.log(res)
        },
        err => console.log(err),
      );
    }
  }

  initPasswordForm(id): void {

    console.log('init pass');
    this.changePasswordForm = new FormGroup({
      userId: new FormControl(id, Validators.required),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, {
      updateOn: 'change'
    });
  }

  change(): void{
    console.log(this.changePasswordForm.value);
    this.adminService.changePassword(this.changePasswordForm.value).subscribe(
      res => {
        console.log(res);
        this.closeModal.nativeElement.click();
        this.toastrService.success('The password is a change');
      },
      err => console.log(err)
    );
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getManagerWeek();
  }

  getManagerWeek(): void{
    console.log('wwwwwwwww', this.query);
    this.regionalManagerService.getMyUsers(this.query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.userList = res.result;
        this.totals = res.totals;
      },
      err => console.log(err)
    );
  }

  selectSpecUser(manager): void {
    this.specUserManagerData = manager;
    console.log(this.specUserManagerData);
  }

  changeWeeklyStart(): void {
    this.regionalManagerService.changeWeeklyStart(this.weeklyStartForm.value, this.selectedUser).subscribe(
      res => {
        console.log(res);
        this.closeWeekModal.nativeElement.click();

      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('We failed to change weekly start.');
      }
    );
  }

  selectUser(user): void {
    this.selectedUser = user;
    this.createUpdateWeeklyStartForm(user);
  }

  createUpdateWeeklyStartForm(user): void {
    this.weeklyStartForm = new FormGroup({
      weeklyStart: new FormControl(user?.weeklyStart || 0, Validators.required),
    }, {
      updateOn: 'change'
    });
  }

}
