export enum TransactionTypeEnum {
  "Deposit" = 0,
  "Withdrawal" = 1,
  "ManualDeposit" = 2,
  "ManualWithdrawal" = 3,
  "Bonus" = 4,
  "Transfer" = 10,
  "DepositGurush" = 11,
  "DepositeByCode" = 20,
  "Subscription" = 90,
  "Bet" = 100,
  "Win" = 200
}
