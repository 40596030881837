<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl">
    <div class="card mb-5 mb-xl-8" *ngIf="managerInfo">
      <div class="card-body py-6">
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'MANAGER_NAME' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <input type="text" class="form-control" placeholder="{{'MANAGER_NAME' | translate}}" disabled [(ngModel)]="managerInfo.username" autocomplete="off" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'CHOOSE_A_WEEK' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <select  class="form-control" [(ngModel)]="weekIndex" (ngModelChange)="getUserWeekly()">
              <option *ngIf="weeks.length >= 1" value="0">{{'CURRENT_WEEKS' | translate}}</option>
              <option *ngIf="weeks.length >= 2" value="1">{{'LAST_WEEK' | translate}}</option>
              <option *ngIf="weeks.length >= 3" value="2">{{'TWO_WEEKS_AGO' | translate}}</option>
              <option *ngIf="weeks.length >= 4" value="3">{{'THREE_WEEKS_AGO' | translate}}</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'POINTS_START' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{managerInfo.managerWeeklyStart}}
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'POINTS_START_NEXT_WEEK' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{managerInfo.nextWeeklyStart}}
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'POINTS_ADDED' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{managerInfo.pointsAdded}}
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'POINTS_SPENT' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{(managerInfo.managerWeeklyStart +  managerInfo.managerPointsAdded) - managerInfo.managerCurrentBalance}}
            </div>
            <!-- {{(managerInfo.managerWeeklyStart +  managerInfo.managerPointsAdded) - managerInfo.managerCurrentBalance}} -->
<!--            <input type="text" class="form-control" placeholder="Points Spent"  [(ngModel)]="(managerInfo.managerWeeklyStart +  managerInfo.managerPointsAdded) - managerInfo.managerCurrentBalance"/>-->
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'POINTS_AVAILABLE' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{managerInfo.managerCurrentBalance}}
            </div>
<!--            <input type="text" class="form-control" placeholder="Points Available"  [(ngModel)]="managerInfo.managerCurrentBalance"/>-->
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'MY_PLAYERS_BALANCE' | translate}}</p>
          </div>

          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{managerInfo.playersBalance}}
            </div>
<!--            <input type="text" class="form-control" placeholder="My Players Balance" [(ngModel)]="managerInfo.playersBalance"/>-->
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'NUMBER_PLAYERS' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{managerInfo.numberOfUsers}}
            </div>
<!--            <input type="text" class="form-control" placeholder="Number Of Players" [(ngModel)]="managerInfo.numberOfUsers"/>-->
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'POINTS_DONE' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <!-- {{managerInfo.playersBalance - (managerInfo.weeklyStart + managerInfo.pointsAdded )}} -->
            <div class="form-control">
              {{managerInfo.managerCommission + managerInfo.roulletteManagerCommission}}
            </div>
<!--            <input type="text" class="form-control" placeholder="Points I Done"  [(ngModel)]="(managerInfo.weeklyStart + managerInfo.pointsAdded ) - managerInfo.playersBalance"/>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
