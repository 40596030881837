<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl">
    <div class="card mb-5 mb-xl-8">
      <div class="card-body py-6">
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'USERNAME' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <input type="text" class="form-control" placeholder="Manager Name" disabled [(ngModel)]="transaction.userPhoneNumber" autocomplete="off">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'TRANSACTION_TYPE' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{transactionTypeEnum[transaction.transactionType]}}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'GAME_TYPE' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{gameTypeEnum[transaction.gameType]}}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'Amount' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{transaction.amount}}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'DESCRIPTION' | translate}}</p>
          </div>

          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{transaction.description}}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'TIME' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <div class="form-control">
              {{transaction.time}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
