<div class="toolbar" id="kt_toolbar">
  <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
      <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{'SEARCH_USER' | translate}}</h1>
      <span class="h-20px border-gray-200 border-start mx-4"></span>

      <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'HOME' | translate}}</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="breadcrumb-item text-muted">Widgets</li> -->
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li> -->
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-dark">{{'SEARCH_USER' | translate}}</li>
        <!--end::Item-->
      </ul>
    </div>
  </div>
</div>

<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl container-full-width">
    <div class="card mb-5 mb-xl-8">
      <div class="card-header pt-5 pb-5">
        <div class="row w-100">
          <div class="col-xs-12 col-lg-2 d-flex align-items-center">
            <span class="me-3">{{'SEARCH_TERM' | translate}}</span>
          </div>
          <div class="col-xs-12 col-lg-10 d-flex align-items-center flex-wrap">
            <input type="text" [(ngModel)]="query.SearchTerm" class=" form-control mt-2 mb-2 w-25 btn-sm me-3">
            <a class="btn btn-sm btn-primary" (click)="search()" >{{'CONFIRM' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="card-body py-3">
        <div class="table-responsive">
          <table class="table table-row-dashed table-striped table-row-gray-300 align-middle gy-4 gs-5">
            <thead>
            <tr>
              <th scope="col">{{'ID' | translate}}</th>
              <th scope="col">{{'AVATAR' | translate}}</th>
              <th scope="col">{{'USERNAME' | translate}}</th>
              <th scope="col">{{'NICKNAME' | translate}}</th>
              <th scope="col">{{'TOTAL_MAIN_BALANCE' | translate}}</th>
              <th scope="col">{{'ROLE' | translate}}</th>
              <th scope="col">{{'POKER' | translate}}</th>
              <th scope="col">{{'ROULETTE' | translate}}</th>
              <th scope="col">{{'TRANSACTIONS' | translate}}</th>
              <th scope="col">{{'DETAILS' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of userList">
              <td>{{user.userId}}</td>
              <td>
                <div class="symbol symbol-45px me-5">
                  <img [src]="user.avatar || '../../../../../assets/media/avatars/150-11.jpg'" alt="">
                </div>
              </td>
              <td>{{user.userName}}</td>
              <td>{{user.fullName}}</td>
              <td>{{user.totalMainBalance}}</td>
              <td>{{roles[user.role]}}</td>
              <td>
                <button type="button" [routerLink]="['/admin', 'user-log', user.userId, 'poker']" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#deleteUser">
                  {{'POKER' | translate}}Poker
                </button>
              </td>
              <td>
                <button type="button"  [routerLink]="['/admin', 'user-log', user.userId, 'roulette']" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#deleteUser">
                  {{'ROULETTE' | translate}}
                </button>
              </td>
              <td>
                <button type="button"  [routerLink]="['/admin', 'user-log', user.userId, 'transactions']" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#deleteUser">
                  {{'TRANSACTIONS' | translate}}
                </button>
              </td>
              <td>
                <button type="button"   [routerLink]="['/admin', 'user-log', user.userId, 'details']"  class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#deleteUser">
                  {{'DETAILS' | translate}}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [(pageSize)]='query.PageSize' [(page)]="query.PageNumber" (pageChange)="changePage($event)"></ngb-pagination>

  </div>
</div>
