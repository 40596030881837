import { Component, OnInit } from '@angular/core';
import {ManagerUserWeekQuery} from '../../../../../core/models/ManagerUserWeekQuery';
import {WeekEnum} from '../../../../../core/enum/WeekEnum';
import {UserService} from '../../../../../core/services/user/user.service';
import {AdminService} from '../../../../../core/services/admin/admin.service';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../../../core/services/data/data.service';
import {concatMap, tap} from 'rxjs/operators';
import {ManagerService} from '../../../../../core/services/manager/manager.service';
import {Store} from '@ngxs/store';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-manager-roulette-users-weekly',
  templateUrl: './manager-roulette-users-weekly.component.html',
  styleUrls: ['./manager-roulette-users-weekly.component.scss']
})
export class ManagerRouletteUsersWeeklyComponent implements OnInit {
  public query: ManagerUserWeekQuery = new ManagerUserWeekQuery(1, 10, null, null);
  public userList = [];
  public managerList = [];
  public weekEnum = WeekEnum;
  public weekIndex;
  public totals = null;
  public collectionSize = null;
  constructor(private userService: UserService,
              private managerService: ManagerService,
              private route: ActivatedRoute,
              private store: Store,
              private toastrService: ToastrService,
              private dataService: DataService) { }

  ngOnInit(): void {
    const id = this.store.snapshot().eliteAuth.user.userId
    this.route.params.subscribe((params) => {
      this.weekIndex = params.id
      this.dataService.getWeeks().pipe(
        tap((res) => console.log(res)),
        tap(weeks => {
          return this.query = new ManagerUserWeekQuery(1, 10,  weeks[params.id].id, id);

        }),
        concatMap(managersData => {
          this.managerList = managersData.result;
          return  this.managerService.getManagerPlayers(this.query)}
        )).subscribe(
        res => {
          this.userList = res.result;
          this.collectionSize = res.count;
          this.totals = res.totals;
          console.log(res);
        },
        err => console.log(err)
      )
    })

  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getUserWeekly();
  }

  getUserWeekly(): void {
    this.userService.getUserWeek(this.query).subscribe(
      res => {
        this.userList = res.result;
        this.collectionSize = res.count;
        this.totals = res.totals;
      },
      err => console.log(err)
    )
  }

  changeStatus({userId, status}): void {
    if(!status) {
      this.managerService.blockUser(userId, userId).subscribe(
        res => {
          this.toastrService.success('User is blocked');
          console.log(res)
        },
        err => console.log(err),
      );
    }

    if(status) {
      this.managerService.unblockUser(userId, userId).subscribe(
        res => {
          this.toastrService.success('User is unblocked');
          console.log(res)
        },
        err => console.log(err),
      );
    }
  }

}
