import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../../core/services/data/data.service';
import {concatMap, tap} from 'rxjs/operators';
import {ManagerUserWeekQuery} from '../../../../core/models/ManagerUserWeekQuery';
import {UserService} from '../../../../core/services/user/user.service';
import {WeekEnum} from '../../../../core/enum/WeekEnum';
import {ManagerService} from '../../../../core/services/manager/manager.service';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {ToastrService} from "ngx-toastr";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-user-status',
  templateUrl: './user-weekly-status.html',
  styleUrls: ['./user-weekly-status.component.scss']
})
export class UserWeeklyStatus implements OnInit {
  public query: ManagerUserWeekQuery = new ManagerUserWeekQuery(1, 10, null, null);
  public userList = [];
  public managerList = [];
  public weekEnum = WeekEnum;
  public weekIndex;
  public totals = null;
  public collectionSize = null;
  public selectedUser = null;
  public weeklyStartForm;

  @ViewChild('closeWeekModal') closeWeekModal;
  constructor(private userService: UserService,
              private adminService: AdminService,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              private toastrService: ToastrService,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.createUpdateWeeklyStartForm(null)
    this.route.params.subscribe((params) => {
      this.weekIndex = params.id
      this.dataService.getWeeks().pipe(
        tap((res) => console.log(res)),
        concatMap(weeks => {
          this.query = new ManagerUserWeekQuery(1, 10,  weeks[params.id].id);
          const data = {
            Role: 100,
            PageNumber: 1,
            PageSize: 10000
          };
          return this.adminService.getAllManager(data);
        }),
        concatMap(managersData => {
          this.query.ManagerId = managersData.result[0].userId;
          this.managerList = managersData.result;
          return  this.userService.getUserWeek(this.query)}
        )).subscribe(
        res => {
          this.userList = res.result;
          this.collectionSize = res.count;
          this.totals = res.totals;
          console.log(res);
        },
        err => console.log(err)
      )
    })

  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getUserWeekly();
  }

  getUserWeekly(): void {
    this.userService.getUserWeek(this.query).subscribe(
      res => {
        this.userList = res.result;
        this.collectionSize = res.count;
        this.totals = res.totals;
      },
      err => console.log(err)
    )
  }

  changeStatus({userId, status}): void {
    if (!status) {
      this.adminService.blockUser(userId).subscribe(
        res => {
          this.toastrService.success('User is blocked');
          console.log(res)
        },
        err => console.log(err),
      );
    }

    if (status) {
      this.adminService.unblockUser(userId).subscribe(
        res => {
          this.toastrService.success('User is unblocked');
          console.log(res)
        },
        err => console.log(err),
      );
    }
  }


  changeWeeklyStart(): void {
    console.log(this.weeklyStartForm.value);
    this.adminService.changeWeeklyStart(this.weeklyStartForm.value, this.selectedUser).subscribe(
      res => {
        console.log(res);
        this.getUserWeekly();
        this.closeWeekModal.nativeElement.click();

      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('We failed to change weekly start.');
      }
    );
  }


  selectUser(user): void {
    this.selectedUser = user;
    this.createUpdateWeeklyStartForm(user);
  }

  createUpdateWeeklyStartForm(user): void {
    this.weeklyStartForm = new FormGroup({
      weeklyStart: new FormControl(user?.weeklyStart || 0, Validators.required),
      percent: new FormControl(user?.percent || 0, Validators.required),
    }, {
      updateOn: 'change'
    });
  }

}
