<div class="toolbar" id="kt_toolbar">
  <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
      <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{'JACK_LIST' | translate}}</h1>
      <span class="h-20px border-gray-200 border-start mx-4"></span>
      <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
        <li class="breadcrumb-item text-muted">
          <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'HOME' | translate}}</a>
        </li>
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li>
        <li class="breadcrumb-item text-dark">{{'JACKPOT_ADDED_ADMIN_LIST' | translate}}</li>
      </ul>
    </div>
  </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl container-full-width">
    <div class="card mb-5 mb-xl-8">
      <div class="card-body py-3">
        <div class="table-responsive">
          <table class="table table-row-dashed table-striped table-row-gray-300 align-middle gy-4 gs-5">
            <thead>
            <tr>
              <th scope="col">{{'ADMIN_USER_ID' | translate}}</th>
              <th scope="col">{{'ADMIN_FULL_NAME' | translate}}</th>
              <th scope="col">{{'ADMIN_USER_NAME' | translate}}</th>
              <th scope="col">{{'GAME_KEY' | translate}}</th>
              <th scope="col">{{'JACKPOT_AMOUNT_ADDED' | translate}}</th>
              <th scope="col">{{'NEXT_JACKPOT_WINNER_FULL_NAME' | translate}}</th>
              <th scope="col">{{'NEXT_JACKPOT_WINNER_USERID' | translate}}</th>
              <th scope="col">{{'NEXT_JACKPOT_WINNER_USER_NAME' | translate}}</th>
              <th scope="col">{{'TIME' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let jackpot of jackpotList">
              <td>{{jackpot.adminUserId}}</td>
              <td>{{jackpot.adminFullName}}</td>
              <td>{{jackpot.adminUserName}}</td>
              <td>{{jackpot.gameKey}}</td>
              <td>{{jackpot.jackpotAmountAdded}}</td>
              <td>{{jackpot.nextJackpotWinnerFullName}}</td>
              <td>{{jackpot.nextJackpotWinnerUserId}}</td>
              <td>{{jackpot.nextJackpotWinnerUserName}}</td>
              <td>{{jackpot.time | date: 'medium'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [(pageSize)]='query.PageSize' [(page)]="query.PageNumber" (pageChange)="changePage($event)"></ngb-pagination>
  </div>
</div>





