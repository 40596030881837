import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AdminService} from "../../../../core/services/admin/admin.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-regional-manager-list',
  templateUrl: './regional-manager-list.component.html',
  styleUrls: ['./regional-manager-list.component.scss']
})
export class RegionalManagerListComponent implements OnInit {
  public managerList = [];
  public changePasswordForm: FormGroup;
  public selectedUser = null;
  @ViewChild('closeModal') closeModal;
  @ViewChild('deleteUser') deleteUser;

  public data = {
    Role: 101,
    PageNumber: 1,
    PageSize: 1000
  };

  constructor(private adminService: AdminService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.initPasswordForm(null);
    this.getManager(this.data);
  }

  getManager(data): void {
    this.adminService.getAllManager(data).subscribe(
      res => {
        console.log(res);
        this.managerList = res.result;
      },
      err => console.log(err),
    )
  }

  // "userId": 0,
  // "newPassword": "stringst"

  initPasswordForm(id): void {
    this.changePasswordForm = new FormGroup({
      userId: new FormControl(id, Validators.required),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, {
      updateOn: 'change'
    });

  }

  change(){
    console.log(this.changePasswordForm.value)
    this.adminService.changePassword(this.changePasswordForm.value).subscribe(
      res => {
        console.log(res);
        this.closeModal.nativeElement.click();
        this.toastrService.success('The password is a change');
      },
      err => console.log(err)
    )
  }

  deletePlayerModal(player): void {
    this.selectedUser = player;
  }

  deletePlayer(): void {
    this.adminService.deleteUser(this.selectedUser).subscribe(
      res => {
        console.log(res);
        this.getManager(this.data);
        this.deleteUser.nativeElement.click();
        this.toastrService.success('Manager is Deleted');
      },
      err => {
        console.log(err);
        this.deleteUser.nativeElement.click();
      }
    );
  }

  changeStatus({userId, status}): void {
    if (!status) {
      this.adminService.blockUser(userId).subscribe(
        res => {
          this.toastrService.success('User is blocked');
          this.getManager(this.data);
          console.log(res);
        },
        err => console.log(err),
      );
    }

    if(status) {
      this.adminService.unblockUser(userId).subscribe(
        res => {
          this.toastrService.success('User is unblocked');
          this.getManager(this.data);
          console.log(res)
        },
        err => console.log(err),
      );
    }
  }

}
