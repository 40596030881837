import { Component, OnInit } from '@angular/core';
import {RoleEnum} from '../../../../../core/enum/Role';
import {SearchPokerGamesForUser, SearchTransactionsForUser} from '../../../../../core/models/ManagerUserWeekQuery';
import {AdminService} from '../../../../../core/services/admin/admin.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TransactionTypeEnum} from '../../../../../core/enum/TransactionTypeEnum';
import {GameTypeEnum} from '../../../../../core/enum/GameTypeEnum';

@Component({
  selector: 'app-show-user-transactions',
  templateUrl: './show-user-transactions.component.html',
  styleUrls: ['./show-user-transactions.component.scss']
})
export class ShowUserTransactionsComponent implements OnInit {

  public gameList = [];
  public userId = null;
  public transactionTypeEnum = TransactionTypeEnum;
  public gameTypeEnum = GameTypeEnum;
  public collectionSize = null;
  public roles = RoleEnum;
  public query = new SearchTransactionsForUser(1, 10, '');

  constructor(private adminService: AdminService,
              private route: ActivatedRoute,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    const {id} = this.route.snapshot.params;
    if (id) {
      this.userId = id;
      this.query.UserId = id;
      this.search()
    }
  }

  search(): void {
    this.adminService.getUserTransactionsById(this.query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.gameList = res.result;
      },
      err => console.log(err)
    )
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.search();
  }

  setFromTime(value): void {
    this.query.From = value;
  }
  setToTime(value): void{
    this.query.To = value;
  }
}
