import { Component, OnInit } from '@angular/core';
import {RoleEnum} from '../../../../../core/enum/Role';
import {SearchForUser, SearchPokerGamesForUser} from '../../../../../core/models/ManagerUserWeekQuery';
import {AdminService} from '../../../../../core/services/admin/admin.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-show-poker-games',
  templateUrl: './show-poker-games.component.html',
  styleUrls: ['./show-poker-games.component.scss']
})
export class ShowPokerGamesComponent implements OnInit {
  public gameList = [];
  public userId = null;
  public collectionSize = null;
  public roles = RoleEnum;
  public query = new SearchPokerGamesForUser(1, 10, '');

  constructor(private adminService: AdminService,
              private route: ActivatedRoute,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    const {id} = this.route.snapshot.params;
    if (id) {
      this.query.UserId = id;
      this.userId = id;
      this.search()
    }
  }

  search(): void {
    this.adminService.searchPokerGamesForUser(this.query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.gameList = res.result;
      },
      err => console.log(err)
    )
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.search();
  }

  setFromTime(value): void {
    this.query.From = value;
  }
  setToTime(value): void{
    this.query.To = value;
  }

}
