<div class="toolbar" id="kt_toolbar">
  <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
      <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{'BALANCE_CORRECTION' | translate}}</h1>
      <span class="h-20px border-gray-200 border-start mx-4"></span>

      <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
        <li class="breadcrumb-item text-muted">
          <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'HOME' | translate}}</a>
        </li>
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li>
        <li class="breadcrumb-item text-dark">{{'BALANCE_CORRECTION' | translate}}</li>
      </ul>
    </div>
  </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl">
    <form  *ngIf="managerForm" class="card mb-5 mb-xl-8" [formGroup]="managerForm" (submit)="create()">
      <div class="card-body py-6">
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'PLAYER' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <select name="" id="" *ngIf="refreshSelect" class="form-select" formControlName="UserId">
              <option [value]="user.userId" *ngFor="let user of userList"> {{user.userName}}</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-md-4">
            <p class="mb-0 mt-4">{{'AMOUNT' | translate}}</p>
          </div>
          <div class="col-xs-12 col-md-8">
            <input type="text" class="form-control" formControlName="Amount" placeholder="" autocomplete="off" />
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-between py-6">
        <button type="button" (click)="clear()" class="btn btn-warning">{{'CANCEL' | translate}}</button>
        <button type="submit" class="btn btn-primary" [disabled]="managerForm.invalid">{{'CONFIRM' | translate}}</button>
      </div>
    </form>
  </div>
</div>





