import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {


  constructor(private http: HttpClient) { }

  createManager(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/admin/manager`, data);
  }

  manualCorrection(data: any): Observable<any> {
    return this.http.post<any>( environment.serverAccountsURL + `/admin/manualcorrection`, data);
  }

  editJackpot(data: any, gameKey): Observable<any> {
    return this.http.put<any>( environment.serverPokerURL + `/admin/tables/${gameKey}/jackpot`, data);
  }

  getRegionalManagerWeek(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/adminmanager/regionalmanagers`, {params:{...data}});
  }

  getPlayersOnTable(gameKey): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/admin/tables/${gameKey}/users`);
  }

  getAllTables(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/admin/tables`, {params:{...data}});
  }

  getAllManager(data): Observable<any> {
    return this.http.get<any>( environment.serverURL + `/admin/users`, {params:{...data}});
  }

  getUserListByManager(data): Observable<any> {
    return this.http.get<any>( environment.serverURL + `/admin/users`, {params: {...data}});
  }

  searchForUser(data): Observable<any> {
    return this.http.get<any>( environment.serverURL + `/admin/users`, {params: {...data}});
  }


  getAllJackpotList(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/admin/wonjackpotbyuser`, {params: {...data}});
  }

  getAddedByAdmin(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/admin/addedjackpotbyadmin`, {params: {...data}});
  }

  searchPokerGamesForUser(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/admin/gameplayed`, {params: {...data}});
  }

  getUserTransactionsById(data): Observable<any> {
    return this.http.get<any>( environment.serverAccountsURL + `/admin/transactions`, {params: {...data}});
  }

  getGameLog(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/admin/log`, {params: {...data}});
  }

  getGameRounds(data): Observable<any> {
    return this.http.get<any>( environment.virtualGame + `/admin/gameRounds`, {params: {...data}});
  }

  getUserById(data): Observable<any> {
    return this.http.get<any>( environment.serverURL + `/admin/users`, {params: {...data}});
  }

  getMyStatus(data): Observable<any> {
    return this.http.get<any>( environment.serverURL + `/admin/managerstats`, {params: {...data}});
  }

  changePassword(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/admin/changepassword`, data);
  }

  transferFromManager(data: any): Observable<any> {
    return this.http.post<any>( environment.serverAccountsURL + `/admin/manualwithdrawal`, data);
  }

  transferToManager(data: any): Observable<any> {
    return this.http.post<any>( environment.serverAccountsURL + `/admin/manualdeposit`, data);
  }

  transferToPokerManager(data: any): Observable<any> {
    return this.http.post<any>( environment.serverAccountsURL + `/admin/transfer`, data);
  }

  transferFromPokerManager(data: any): Observable<any> {
    return this.http.post<any>( environment.serverAccountsURL + `/admin/transferfromsub`, data);
  }

  blockUser(userId: any): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/admin/${userId}/blockuser`, {});
  }

  unblockUser(userId: any): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/admin/${userId}/unblockuser`, {});
  }

  messageToActiveUser(msg: any): Observable<any> {
    return this.http.post<any>( environment.serverPokerURL + `/admin/messagetoactiveusers`, msg);
  }

  lockTable(data: any, gameKey: any): Observable<any> {
    return this.http.put<any>( environment.serverPokerURL + `/admin/tables/${gameKey}/password`, data);
  }

  deleteUser(userId: any): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/admin/${userId}/delete`, {});
  }

  getUserBalance(userId): Observable<any> {
    return this.http.get<any>( environment.serverURL + `/admin/${userId}/balance`);
  }

  changeWeeklyStart(data, userId: any, ): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/admin/manager/${userId}/weeklystart`, data);
  }
}
