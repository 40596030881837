import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {TableQuery} from '../../../../core/models/TableQuery';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-active-tables',
  templateUrl: './active-tables.component.html',
  styleUrls: ['./active-tables.component.scss']
})
export class ActiveTablesComponent implements OnInit {
  public selectedTable = null;
  public allTables = [];
  public players: any[] = [];
  public lockTableForm = null;
  public editJackpotForm = null;
  public query = new TableQuery(1, 10);
  public collectionSize = null;
  public tableChars = {
    '6': [1,2,3,4,5,6,],
    '7': [1,2,3,4,5,6,7],
    '9': [1,2,3,4,5,6,7,8,9]
  }

  @ViewChild('closePassModal') closePassModal;
  @ViewChild('editJackpot') editJackpot;

  constructor(private adminService: AdminService,
              private translateService: TranslateService,
              private toasterService: ToastrService) { }

  ngOnInit(): void {
    this.initLockForm(null);
    this.initJackpotForm(null);
    this.getTables();
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getTables();
  }

  getTables(): void {
    this.adminService.getAllTables(this.query).subscribe(
      res => {
        this.allTables = res.result;
        this.collectionSize = res.count;
        console.log('Tables' ,this.allTables);
      },
      async err => {
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toasterService.error(msg);
          return;
        }
        console.log(err);
      }
    );
  }

  initLockForm(data): void {
    console.log(data)
    this.selectedTable = data;

    this.lockTableForm = new FormGroup({
      password: new FormControl(data?.password, Validators.required),
      isLocked: new FormControl(data?.hasPassword, Validators.required),
    }, {
      updateOn: 'change'
    });
  }

  initJackpotForm(data): void {
    console.log(data)
    this.selectedTable = data;

    if(this.selectedTable?.gameKey) {
      this.getUserOnTable();
    }

    this.editJackpotForm = new FormGroup({
      minJackpotAmount: new FormControl(data?.minJackpotAmount, Validators.required),
      maxJackpotAmount: new FormControl(data?.maxJackpotAmount, Validators.required),
      currentJackpotAmount : new FormControl(data?.currentJackpotAmount),
      oldJackpotAmount : new FormControl(data?.currentJackpotAmount),
      nextJackpotGoesToChairNumber:
        new FormControl( this.selectedTable?.nextJackpotGoesToChairNumber === 0 ?
          'none' : this.selectedTable?.nextJackpotGoesToChairNumber, []),
    }, {
      updateOn: 'change'
    });
  }

  getUserOnTable(): void {
    this.adminService.getPlayersOnTable(this.selectedTable?.gameKey).subscribe(
      res => {
          this.players = res;
      },
      err => console.log(err)
    )
  }

  changeLockStatus(): void {
    console.log(this.lockTableForm.value)
    this.adminService.lockTable(this.lockTableForm.value, this.selectedTable.gameKey).subscribe(
      res => {
        this.initLockForm(null);
        this.closePassModal.nativeElement.click();
        this.getTables();
      }
    )
  }

  updateJackpot(): void {
    console.log(this.editJackpotForm.value)

    if(this.editJackpotForm.controls['nextJackpotGoesToChairNumber'].value === 'none') {
      this.editJackpotForm.controls['nextJackpotGoesToChairNumber'].setValue(null)
    }

    this.adminService.editJackpot(this.editJackpotForm.value, this.selectedTable.gameKey).subscribe(
      res => {
          console.log(res)
          this.getTables();
          this.editJackpot.nativeElement.click();

      },
      async err => {
        this.editJackpot.nativeElement.click();
        this.getTables();

        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toasterService.error(msg);
          return;
        }
        console.log(err);
      }
    );
  }

}
