import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RegionalManagerWeekQuery} from '../../../../core/models/ManagerUserWeekQuery';
import {Store} from '@ngxs/store';
import {RegionalManagerService} from '../../../../core/services/regional-manager/regional-manager.service';
import {concatMap, tap} from 'rxjs/operators';
import {DataService} from '../../../../core/services/data/data.service';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-my-managers-list',
  templateUrl: './my-managers-list.component.html',
  styleUrls: ['./my-managers-list.component.scss']
})
export class MyManagersListComponent implements OnInit {
  public selectedUser = null;
  userList: any[] = [];
  myId: number = null;
  public changePasswordForm: FormGroup;
  public collectionSize = null;
  public query =  new RegionalManagerWeekQuery(1, 10,  null);
  @ViewChild('closeModal') closeModal;
  @ViewChild('deleteUser') deleteUser;

  constructor(private regionalManagerService: RegionalManagerService,
              private store: Store,
              private toastrService: ToastrService,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.initPasswordForm(null);
    this.dataService.getWeeks().pipe(
      tap((res) => console.log(res)),
      concatMap(weeks => {
        this.query = new RegionalManagerWeekQuery(1, 10,  weeks[0].id);
        this.query.HideDeleted = true;
        return this.regionalManagerService.getMyManagers(this.query)}
      )).subscribe(
      res => {
        this.collectionSize = res.count;
        this.userList = res.result;
      },
      err => console.log(err)
    )
  }

  getPlayers(query): void {
    this.regionalManagerService.getMyManagers(query).subscribe(
      res => {
        console.log(res);
        this.collectionSize = res.count;
        this.userList = res.result;
      },
      err => console.log(err),
    )
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getPlayers(this.query);
  }


  initPasswordForm(id): void {
    this.changePasswordForm = new FormGroup({
      userId: new FormControl(id, Validators.required),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, {
      updateOn: 'change'
    });

  }

  change(): void {
    console.log(this.changePasswordForm.value)
    this.regionalManagerService.changeUserPassword(this.changePasswordForm.value).subscribe(
      res => {
        console.log(res);
        this.closeModal.nativeElement.click();
        this.toastrService.success('The password is a change');
      },
      err => console.log(err)
    );
  }

  deletePlayerModal(player): void {
    this.selectedUser = player;
  }

  deletePlayer({userId}): void {
    console.log('nece klik');
    this.regionalManagerService.deleteUser(userId).subscribe(
      res => {
        console.log(res);
        this.getPlayers(this.query);
        this.deleteUser.nativeElement.click();
        this.toastrService.success('Manager is Deleted');
      },
      err => {
        console.log(err);
        this.deleteUser.nativeElement.click();
      }
    );
  }

}
