import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {AuthService} from '../../../core/services/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {Login} from '../../../store/auth/auth.actions';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loader: boolean = false;

  constructor(private router: Router,
              private authService: AuthService,
              private translateService: TranslateService,
              private toasterService: ToastrService,
              private store: Store) { }

  ngOnInit(): void {
    this.createLoginForm();
  }

  createLoginForm(): void {
    this.loginForm = new FormGroup({
      deviceId: new FormControl('', []),
      phoneNumber: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    }, {
      updateOn: 'submit'
    });

    const deviceId = this.store.snapshot().eliteAuth.deviceId;

    if (deviceId) {
      this.loginForm.controls.deviceId.setValue(deviceId);
    }
  }

  async login(): Promise<void> {

    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) { return; }

    const data = {
      phoneNumber: this.loginForm.value.phoneNumber,
      deviceId: this.loginForm.value.deviceId,
      password: this.loginForm.value.password,
    };
    this.loader = true;
    this.authService.login(data).subscribe(
      async res => {
        this.loader = false;
        console.log(res);
        this.store.dispatch(new Login(res));
        if (res.verificationType === 1) {
          await this.router.navigate(['/otp']);
        }
        if (res.verificationType === 0) {

          switch (res.user.role) {
            case 2:
              await this.router.navigate(['admin/user-weekly-status/0']);
              break;
            case 100:
              await this.router.navigate(['manager/all-manager-player']);
              break;
            case 101:
              await this.router.navigate(['regional/manager-weekly-status/0']);
              break;
            default:
              console.log(`Sorry.`);
          }

        }
      },
      async err => {
        console.log(err);
        this.loader = false;
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toasterService.error(msg);
          return;
        }
        this.toasterService.error('Server not responding');
      }
    );
  }

}
