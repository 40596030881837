
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/login/admin`, data);
  }

  logout(data?): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/login/logout`, data);
  }

  verifyOtp(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/login/otp`, data);
  }

  resendOtp(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/login/resendotp`, data);
  }

  changePassword(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/user`, data);
  }

  getUserDetails(): Observable<any> {
    return this.http.get<any>( environment.serverURL + `/user`);
  }

  resetPassword(data: any ): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/login/recover/requestsms`, data);
  }

  setNewPassword(data: any ): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/login/recover/confirm`, data);
  }

}
