import { Component, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {JackpotQuery} from '../../../../core/models/ManagerUserWeekQuery';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-jackpot-added-by-admin',
  templateUrl: './jackpot-added-by-admin.component.html',
  styleUrls: ['./jackpot-added-by-admin.component.scss']
})
export class JackpotAddedByAdminComponent implements OnInit {
  jackpotList: any[] = [];
  public changePasswordForm: FormGroup;
  public collectionSize = null;
  public selectedUser = null;

  public query = new JackpotQuery(1, 10);

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    this.getJackpotList(this.query);
  }

  getJackpotList(query): void {
    this.adminService.getAddedByAdmin(query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.jackpotList = res.result;
      },
      err => console.log(err),
    )
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getJackpotList(this.query);
  }

}
