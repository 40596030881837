import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminService} from "../../../../core/services/admin/admin.service";

@Component({
  selector: 'app-global-messages',
  templateUrl: './global-messages.component.html',
  styleUrls: ['./global-messages.component.scss']
})
export class GlobalMessagesComponent implements OnInit {
  public globalMessagesForm: FormGroup;

  constructor(private adminService: AdminService) { }

  globalMessages() {
    console.log(this.globalMessagesForm.value)

    this.globalMessagesForm.markAllAsTouched();
    if (this.globalMessagesForm.invalid) { return; }
    this.adminService.messageToActiveUser(this.globalMessagesForm.value).subscribe(
      res => {
        console.log(res);
        this.globalMessagesForm.reset();
      },
      err => console.log(err)
    )
  }

  createGlobalMessagesForm(): void {
    this.globalMessagesForm = new FormGroup({
      message: new FormControl('', Validators.required)
    }, {
      updateOn: 'change'
    });
  }

  ngOnInit(): void {
    this.createGlobalMessagesForm();
  }

  clear(): void {
    this.createGlobalMessagesForm();
  }

}
