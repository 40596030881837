import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule} from '@angular/router';
import { TransferComponent } from './Admin/transfer/transfer.component';
import { ManagerListComponent } from './manager-list/manager-list.component';
import { ManagerUsersListComponent } from './manager-users-list/manager-users-list.component';
import { ActiveTablesComponent } from './Admin/active-tables/active-tables.component';
import { CreatePlayerComponent } from './manager/create-player/create-player.component';
import { PlayerListComponent } from './Admin/player-list/player-list.component';
import { RegionalManagersStatus } from './Admin/regional-managers-status/regional-managers-status';
import { UserWeeklyStatus } from './Admin/user-weekly-status/user-weekly-status';
import { ShareModule} from '../../share/share.module';
import { NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessagesComponent } from './Admin/global-messages/global-messages.component';
import { AllManagerPlayerComponent } from './manager/all-manager-player/all-manager-player.component';
import { RegionalManagerManagersComponent } from './regional-manager-managers/regional-manager-managers.component';
import { RegionalManagerListComponent } from './Admin/regional-manager-list/regional-manager-list.component';
import { ManagersStatusComponent } from './Admin/managers-status/managers-status.component';
import { CreateReagionalManagerComponent } from './Admin/create-reagional-manager/create-reagional-manager.component';
import { MyManagersComponent } from './regional-manager/my-managers/my-managers.component';
import { MyManagersUsersComponent } from './regional-manager/my-managers-users/my-managers-users.component';
import { CreatePokerManagerComponent } from './regional-manager/create-poker-manager/create-poker-manager.component';
import { MyManagersListComponent } from './regional-manager/my-managers-list/my-managers-list.component';
import { TransferToPokerManagerComponent } from './regional-manager/transfer-to-poker-manager/transfer-to-poker-manager.component';
import { MainComponent } from './manager/main/main.component';
import { TransferToPlayerComponent } from './manager/transfer-to-player/transfer-to-player.component';
import { PlayerWeeklyStatusComponent } from './manager/player-weekly-status/player-weekly-status.component';
import { TransferRegionalToPokerComponent } from './Admin/transfer-regional-to-poker/transfer-regional-to-poker.component';
import { TransferToPokerPlayerComponent } from './Admin/transfer-to-poker-player/transfer-to-poker-player.component';
import { AdminRouletteRegionalWeeklyComponent } from './Admin/roulette/admin-roulette-regional-weekly/admin-roulette-regional-weekly.component';
import { AdminRouletteManagersWeeklyComponent } from './Admin/roulette/admin-roulette-managers-weekly/admin-roulette-managers-weekly.component';
import { AdminRouletteUsersWeeklyComponent } from './Admin/roulette/admin-roulette-users-weekly/admin-roulette-users-weekly.component';
import { RegionalRouletteUsersWeeklyComponent } from './regional-manager/roulette/regional-roulette-users-weekly/regional-roulette-users-weekly.component';
import { RegionalRouletteManagerWeeklyComponent } from './regional-manager/roulette/regional-roulette-manager-weekly/regional-roulette-manager-weekly.component';
import { ManagerRouletteUsersWeeklyComponent } from './manager/roulette/manager-roulette-users-weekly/manager-roulette-users-weekly.component';
import { MainRegionalComponent } from './regional-manager/main-regional/main-regional.component';
import { SearchUserComponent } from './Admin/search-user/search-user.component';
import { ShowPokerGamesComponent } from './Admin/search-user/show-poker-games/show-poker-games.component';
import { ShowRouletteGamesComponent } from './Admin/search-user/show-roulette-games/show-roulette-games.component';
import { ShowUserTransactionsComponent } from './Admin/search-user/show-user-transactions/show-user-transactions.component';
import { ShowUserDetailsComponent } from './Admin/search-user/show-user-details/show-user-details.component';
import { PokerGameLogComponent } from './Admin/search-user/poker-game-log/poker-game-log.component';
import { TransactionDetailsComponent } from './Admin/search-user/transaction-details/transaction-details.component';
import { VersionComponent } from './admin/version/version.component';
import { ErrorsComponent } from './admin/errors/errors.component';
import { NgSelectModule} from '@ng-select/ng-select';
import { JackpotListComponent } from './Admin/jackpot-list/jackpot-list.component';
import { JackpotAddedByAdminComponent } from './Admin/jackpot-added-by-admin/jackpot-added-by-admin.component';
import { UserBalanceCorrectionComponent } from './Admin/user-balance-correction/user-balance-correction.component';
import { TranslateModule } from '@ngx-translate/core';

export const routes: Route[] = [
  { path: 'admin/regional-managers-status/:id', component: RegionalManagersStatus},
  { path: 'admin/managers-weekly-status/:id', component: ManagersStatusComponent},
  { path: 'admin/user-weekly-status/:id', component: UserWeeklyStatus},
  { path: 'admin/roulette/admin-roulette-managers-weekly/:id', component: AdminRouletteManagersWeeklyComponent},
  { path: 'admin/roulette/admin-roulette-regional-weekly/:id', component: AdminRouletteRegionalWeeklyComponent},
  { path: 'admin/roulette/admin-roulette-users-weekly/:id', component: AdminRouletteUsersWeeklyComponent},
  { path: 'admin/transfer', component: TransferComponent},
  { path: 'admin/transfer-regional-to', component: TransferRegionalToPokerComponent},
  { path: 'admin/create-regional-manager', component: CreateReagionalManagerComponent},
  { path: 'admin/create-poker-player', component: TransferToPokerPlayerComponent},
  { path: 'admin/regional-manager-list', component: RegionalManagerListComponent},
  { path: 'admin/manager-list-regional', component: RegionalManagerManagersComponent},
  { path: 'admin/manager-users-list', component: ManagerUsersListComponent},
  { path: 'admin/active-tables', component: ActiveTablesComponent},
  { path: 'admin/player-list', component: PlayerListComponent},
  { path: 'admin/global-messages', component: GlobalMessagesComponent},
  { path: 'admin/user-log', component: SearchUserComponent},
  { path: 'admin/version', component: VersionComponent},
  { path: 'admin/errors', component: ErrorsComponent},
  { path: 'admin/user-log/:id/details', component: ShowUserDetailsComponent},
  { path: 'admin/user-log/:id/transactions', component: ShowUserTransactionsComponent},
  { path: 'admin/user-log/:id/transactions/:transactionId', component: TransactionDetailsComponent},
  { path: 'admin/user-log/:id/poker', component: ShowPokerGamesComponent},
  { path: 'admin/user-log/:id/roulette', component: ShowRouletteGamesComponent},
  { path: 'admin/user-log/:id/poker/:gameNumber/:gameKey', component: PokerGameLogComponent},
  { path: 'admin/jackpot-list', component: JackpotListComponent},
  { path: 'admin/jackpot-added-by-admin', component: JackpotAddedByAdminComponent},
  { path: 'admin/user-balance-correction', component: UserBalanceCorrectionComponent},


  { path: 'regional/main', component: MainRegionalComponent},
  { path: 'regional/manager-weekly-status/:id', component: MyManagersComponent},
  { path: 'regional/user-weekly-status/:id', component: MyManagersUsersComponent},
  { path: 'regional/roulette/regional-roulette-manager-weekly/:id', component: RegionalRouletteManagerWeeklyComponent},
  { path: 'regional/roulette/regional-roulette-user-weekly/:id', component: RegionalRouletteUsersWeeklyComponent},
  { path: 'regional/create-manager', component: CreatePokerManagerComponent},
  { path: 'regional/manager-list', component: MyManagersListComponent},
  { path: 'regional/transfer-to-poker-manager', component: TransferToPokerManagerComponent},

  { path: 'manager-list', component: ManagerListComponent},
  { path: 'manager/main', component: MainComponent},
  { path: 'manager/all-manager-player', component: AllManagerPlayerComponent},
  { path: 'manager/transfer-to-player', component: TransferToPlayerComponent},
  { path: 'manager/create-player', component: CreatePlayerComponent},
  { path: 'manager/player-weekly-status/:id', component: PlayerWeeklyStatusComponent},
  { path: 'manager/roulette/manager-roulette-player-weekly/:id', component: ManagerRouletteUsersWeeklyComponent},

  { path: 'regional-manager-managers', component: RegionalManagerManagersComponent},
];

@NgModule({
  declarations: [ TransferComponent,  ManagerListComponent,
    ManagerUsersListComponent, ActiveTablesComponent, CreatePlayerComponent,
    PlayerListComponent, RegionalManagersStatus, UserWeeklyStatus, GlobalMessagesComponent,
    AllManagerPlayerComponent, RegionalManagerManagersComponent, RegionalManagerListComponent,
    ManagersStatusComponent, CreateReagionalManagerComponent, MyManagersComponent, MyManagersUsersComponent,
    CreatePokerManagerComponent, MyManagersListComponent, TransferToPokerManagerComponent, MainComponent, TransferToPlayerComponent,
    PlayerWeeklyStatusComponent, TransferRegionalToPokerComponent,
    TransferToPokerPlayerComponent, AdminRouletteRegionalWeeklyComponent,
    AdminRouletteManagersWeeklyComponent, AdminRouletteUsersWeeklyComponent,
    RegionalRouletteUsersWeeklyComponent, RegionalRouletteManagerWeeklyComponent, ManagerRouletteUsersWeeklyComponent,
    MainRegionalComponent, SearchUserComponent, ShowPokerGamesComponent, ShowRouletteGamesComponent,
    ShowUserTransactionsComponent, ShowUserDetailsComponent, PokerGameLogComponent, TransactionDetailsComponent, VersionComponent, ErrorsComponent, JackpotListComponent, JackpotAddedByAdminComponent, UserBalanceCorrectionComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ShareModule,
    NgbPaginationModule,
    NgSelectModule,
    TranslateModule
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardModule { }
