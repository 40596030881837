<div class="modal fade" tabindex="-1" id="kt_modal_1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'LOCK_TABLE' | translate}}</h5>
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" id="closeModal" #closePassModal data-bs-dismiss="modal" aria-label="Close">
                    <span class="svg-icon svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="1" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                        </svg>
                    </span>
        </div>
      </div>
      <div class="modal-body">
        <form class="card mb-5 mb-xl-8" [formGroup]="lockTableForm" (submit)="changeLockStatus()">
          <div class="card-body py-6">
            <div class="row mb-3">
              <div class="col-xs-12 col-md-4">
                <p class="mb-0 mt-4">{{'PASSWORD' | translate}}</p>
              </div>
              <div class="col-xs-12 col-md-8">
                <input type="text" class="form-control"  formControlName="password" placeholder="{{'PASSWORD' | translate}}" autocomplete="off" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-xs-12 col-md-4">
                <p class="mb-0 mt-4">{{'LOCK_TABLE' | translate}}</p>
              </div>
              <div class="col-xs-12 col-md-8">
                <div class="form-check form-switch form-check-custom form-check-solid">
                  <input class="form-check-input" formControlName="isLocked" type="checkbox"  id="flexSwitchDefault" autocomplete="off" />
                  <label class="form-check-label" for="flexSwitchDefault">
                    {{'LOCK_UNLOCK' | translate}}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between py-6">
            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-warning">{{'CANCEL' | translate}}</button>
            <button type="submit" class="btn btn-primary" [disabled]="lockTableForm.invalid">{{'CONFIRM' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" id="kt_modal_2" >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">{{'EDIT_JACKPOT' | translate}}
        <h5 class="modal-title">{{selectedTable?.name}}</h5>
        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" id="editJackpot" #editJackpot data-bs-dismiss="modal" aria-label="Close">
                    <span class="svg-icon svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="1" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                        </svg>
                    </span>
        </div>
        <!--end::Close-->
      </div>
      <div class="modal-body" *ngIf="selectedTable">
        <form class="card mb-5 mb-xl-8" [formGroup]="editJackpotForm" (submit)="updateJackpot()">
          <div class="card-body py-6">
            <div class="row mb-3">
              <div class="col-xs-12 col-md-4">
                <p class="mb-0 mt-4">{{'MIN_JACKPOT_AMOUNT' | translate}}</p>
              </div>
              <div class="col-xs-12 col-md-8">
                <input type="number" min="0" class="form-control"  formControlName="minJackpotAmount" placeholder="{{'MIN_JACKPOT_AMOUNT' | translate}}" autocomplete="off" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-xs-12 col-md-4">
                <p class="mb-0 mt-4">{{'MAX_JACKPOT_AMOUNT' | translate}}</p>
              </div>
              <div class="col-xs-12 col-md-8">
                <input type="number"  min="0" class="form-control"  formControlName="maxJackpotAmount" placeholder="{{'MAX_JACKPOT_AMOUNT' | translate}}" autocomplete="off" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-xs-12 col-md-4">
                <p class="mb-0 mt-4">{{'CURRENT_JACKPOT' | translate}}</p>
              </div>
              <div class="col-xs-12 col-md-8">
                <input type="number"  min="0" class="form-control"  formControlName="currentJackpotAmount" placeholder="{{'CURRENT_JACKPOT' | translate}}" autocomplete="off" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-xs-12 col-md-4">
                <p class="mb-0 mt-4">{{'NEXT_JACKPOT_CHAIR' | translate}}</p>
              </div>
              <div class="col-xs-12 col-md-8">
                <select class="form-control"  formControlName="nextJackpotGoesToChairNumber" >
                  <option value="none">{{'NONE' | translate}}</option>
                  <option [ngValue]="player.chairNumber" *ngFor="let player of players">{{player.username}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between py-6">
            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-warning">{{'CANCEL' | translate}}</button>
            <button type="submit" class="btn btn-primary" [disabled]="editJackpotForm.invalid">{{'CONFIRM' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="toolbar" id="kt_toolbar">
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
             class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{'ACTVIE_TABLES' | translate}}</h1>
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                    <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'HOME' | translate}}</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <!-- <li class="breadcrumb-item text-muted">Widgets</li> -->
                <!--end::Item-->
                <!--begin::Item-->
                <!-- <li class="breadcrumb-item">
                    <span class="bullet bg-gray-200 w-5px h-2px"></span>
                </li> -->
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-dark">{{'ACTVIE_TABLES' | translate}}</li>
            </ul>
        </div>
    </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl container-full-width">
        <div class="card mb-5 mb-xl-8">
            <div class="card-header border-0 pt-5">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder fs-3 mb-1">{{'ACTVIE_TABLES' | translate}}</span>
                </h3>
            </div>
            <div class="card-body py-3">
                <div class="table-responsive">
                    <table class="table table-row-dashed table-striped table-row-gray-300 align-middle gy-4 gs-5">
                        <thead>
                            <tr>
                              <th scope="col">{{'ID' | translate}}</th>
                              <th scope="col">{{'NAME' | translate}}</th>
                              <th scope="col">{{'ACTIVE' | translate}}</th>
                              <th scope="col">{{'SMALL_BLIND' | translate}}</th>
                              <th scope="col">{{'BIG_BLIND' | translate}}</th>
                              <th scope="col">{{'MAX_PLAYERS' | translate}}</th>
                              <th scope="col">{{'LOCKED' | translate}}</th>
                              <th scope="col">{{'ENTER_CODE' | translate}}</th>
                              <th scope="col">{{'CURRENT_JACKPOT' | translate}}</th>
                              <th scope="col">{{'JACKPOT_MIN' | translate}}</th>
                              <th scope="col">{{'JACKPOT_MAX' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let table of allTables" [ngClass]="{'show-active-table': table.numberOfUsersPlaying}">
                                <td>{{table.gameKey}}</td>
                                <td>{{table.gameName}}</td>
                                <td>{{table.isActive}}</td>
                                <td>{{table.smallBlind}}</td>
                                <td>{{table.bigBlind}}</td>
                                <td>{{table.maxPlayers}}</td>
                                <td>{{table.hasPassword ? 'Yes' : 'No'}}
                                    <a (click)="initLockForm(table)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-1"  data-bs-toggle="modal" data-bs-target="#kt_modal_1">
                                        <span class="svg-icon svg-icon-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black"></path>
                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black"></path>
                                            </svg>
                                        </span>
                                    </a>
                                </td>
                                <td>{{table.password}}</td>
                                <td>{{table.currentJackpotAmount}}</td>
                                <td>{{table.minJackpotAmount}}</td>
                                <td>{{table.maxJackpotAmount}}
                                  <a (click)="initJackpotForm(table)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-1"  data-bs-toggle="modal" data-bs-target="#kt_modal_2">
                                        <span class="svg-icon svg-icon-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black"></path>
                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black"></path>
                                            </svg>
                                        </span>
                                  </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      <ngb-pagination [collectionSize]="collectionSize" [(pageSize)]='query.PageSize' [(page)]="query.PageNumber" (pageChange)="changePage($event)"></ngb-pagination>

    </div>
</div>



