<div class="toolbar" id="kt_toolbar">
  <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
      <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{'SEARCH_USER' | translate}} </h1>
      <span class="h-20px border-gray-200 border-start mx-4"></span>

      <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a routerLink="/dashboard" class="text-muted text-hover-primary">{{'HOME' | translate}}</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="breadcrumb-item text-muted">Widgets</li> -->
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li> -->
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-dark">{{'SEARCH_USER' | translate}}</li>
        <!--end::Item-->
      </ul>
    </div>
  </div>
</div>

<div class="post d-flex flex-column-fluid" id="kt_post">
  <div id="kt_content_container" class="container-xxl container-full-width">
    <div class="card mb-5 mb-xl-8">

      <div class="card-header align-items-center">
        <a routerLink="/admin/user-log" class="btn btn-warning btn-sm d-inline-flex align-items-center">
          <svg viewBox="0 0 1792 1792" class="me-1" width="15" height="15" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M1203 544q0 13-10 23L800 960l393 393q10 10 10 23t-10 23l-50 50q-10 10-23 10t-23-10L631 983q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23z"/></svg>
          {{'BACK' | translate}} 
        </a>
      </div>
      <div class="card-body ">
        <div class="row mb-3">
          <div class="col-xs-12 col-lg-2">
            <span class="me-3">{{'FROM_TIME' | translate}}</span>
          </div>
          <div class="col-xs-12 col-lg-6">
            <input type="date" [(ngModel)]="query.From" (ngModelChange)="setFromTime($event)" class=" form-control mt-2 mb-2 btn-sm me-3">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xs-12 col-lg-2">
            <span class="me-3">{{'TO_TIME' | translate}}</span>
          </div>
          <div class="col-xs-12 col-lg-6">
            <input type="date" [(ngModel)]="query.To" class=" form-control mt-2 mb-2 btn-sm me-3">
          </div>
        </div>


        <div class="row mb-3">
          <div class="col-xs-12 col-lg-2">
            <span class="me-3">{{'JUST_WINNING' | translate}}</span>
          </div>
          <div class="col-xs-12 col-lg-6 d-flex align-items-center">
            <select style="min-width: 165px;" class="form-select mt-2 mb-2 btn-sm me-3" aria-label="Select example" [(ngModel)]="query.JustWinning">
              <option [ngValue]="true">{{'TRUE' | translate}}</option>
              <option [ngValue]="false">{{'FALSE' | translate}}</option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xs-12 col-lg-2">
            <span class="me-3">{{'GAME_KEY' | translate}}</span>
          </div>
          <div class="col-xs-12 col-lg-6 d-flex align-items-center">
            <input type="text" [(ngModel)]="query.GameKey" class=" form-control mt-2 mb-2 btn-sm me-3">
            <a class="btn btn-sm btn-primary" (click)="search()" >{{'CONFIRM' | translate}}</a>
          </div>
        </div>

      </div>
      <div class="card-body py-3" *ngIf="gameList.length">
        <div class="table-responsive">
          <table class="table table-row-dashed table-striped table-row-gray-300 align-middle gy-4 gs-5">
            <thead>
            <tr>
              <th scope="col">{{'GAME_KEY' | translate}}</th>
              <th scope="col">{{'GAME_NUMBER' | translate}}</th>
              <th scope="col">{{'TIME' | translate}}</th>
              <th scope="col">{{'DESCRIPTION' | translate}}</th>
              <th scope="col">{{'LOG' | translate}}</th>
            </tr>
            </thead>
            <tbody>
               <tr *ngFor="let game of gameList">
              <td>{{game.gameKey}}</td>
              <td>{{game.gameNumber}}</td>
              <td>{{game.time | date: 'medium' : 'local'}}</td>
              <td>{{game.description | slice : 0 : 30 }}</td>
              <td>
                <button type="button" [routerLink]="['/admin', 'user-log', userId, 'poker', game.gameNumber, game.gameKey]" class="btn btn-primary btn-sm" >
                  {{'DETAILS' | translate}}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ngb-pagination *ngIf="gameList.length" [collectionSize]="collectionSize" [(pageSize)]='query.PageSize' [(page)]="query.PageNumber" (pageChange)="changePage($event)"></ngb-pagination>

  </div>
</div>
