import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GetUsersByRoleAdmin} from '../../../../core/models/ManagerUserWeekQuery';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {Store} from '@ngxs/store';
import {ToastrService} from 'ngx-toastr';
import {concatMap, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-transfer-to-poker-player',
  templateUrl: './transfer-to-poker-player.component.html',
  styleUrls: ['./transfer-to-poker-player.component.scss']
})
export class TransferToPokerPlayerComponent implements OnInit {
  public transferForm: FormGroup;
  public regionalManagerList: any[] = [];
  public pokerManagerList: any[] = [];
  public regionalManagerQuery = new GetUsersByRoleAdmin(1, 10000, 100);
  public pokerManagerQuery = new GetUsersByRoleAdmin(1, 10000, 0);
  public transferToManager = '1';
  public balance: number = null;

  constructor(private adminService: AdminService,
              private store: Store,
              private translateService: TranslateService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.createTransferForm();
    this.getRegionalManager();
  }

  getRegionalManager(): void {
    this.adminService.getAllManager(this.regionalManagerQuery).pipe(
      tap((res) => {
        this.regionalManagerList = res.result;
        this.pokerManagerQuery.ManagerId = res.result[0].userId
        this.getBalance(this.pokerManagerQuery.ManagerId);
        this.transferForm.controls['managerId'].setValue(res.result[0].userId);
      }),
      concatMap(managersData => {
        return this.adminService.getAllManager(this.pokerManagerQuery)}
      )).subscribe(
      res => {
        console.log(res);
        this.pokerManagerList = res.result;
        if (res.result.length) {
          this.transferForm.controls['toUserId'].setValue(res.result[0].userId);
        } else {
          this.transferForm.controls['toUserId'].setValue(null);
        }
      },
      err => console.log(err),
    )
  }

  getManagers(): void {
    this.transferForm.controls['managerId'].setValue(this.pokerManagerQuery.ManagerId);
    this.adminService.getAllManager(this.pokerManagerQuery).subscribe(
      res => {
        console.log(res);
        this.pokerManagerList = res.result;
        this.getBalance(this.pokerManagerQuery.ManagerId);

        if (res.result.length) {
          this.transferForm.controls['toUserId'].setValue(res.result[0].userId);
        } else {
          this.transferForm.controls['toUserId'].setValue(null);
        }
      },
      err => console.log(err),
    )
  }

  createTransferForm(): void {
    this.transferForm = new FormGroup({
      toUserId: new FormControl('', Validators.required),
      amount: new FormControl('', Validators.required),
      managerId: new FormControl('', Validators.required),
      otpNumber: new FormControl(1, []),
      description: new FormControl('Admin transfer', []),
    }, {
      updateOn: 'change'
    });
  }

  transfer(): void {
    console.log(this.transferForm.value)
    console.log(this.transferToManager)

    this.transferForm.markAllAsTouched();
    if (this.transferForm.invalid) { return; }

    if(this.transferToManager === '1') {
      this.transferToPokerManager(this.transferForm.value);
    }

    if(this.transferToManager === '0') {
      this.transferFromPokerManager(this.transferForm.value);
    }

  }

  getBalance(id): void {
    this.adminService.getUserBalance(id).subscribe(
      res => {
        this.balance = res.balance;
      },
      err => {
        console.log(err);
      }
    );
  }

  transferToPokerManager(data): void {
    this.adminService.transferToPokerManager(data).subscribe(
      res => {
        this.resetForm()
        this.toastrService.success('Money successful transferred.');
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('Server not responding');
      }
    );
  }

  transferFromPokerManager(data): void {

    const transferInfo = {
      amount: data.amount,
      otpNumber: 1,
      fromUserId: data.toUserId,
      description: "Admin transfer",
      managerId: data.managerId
    }

    this.adminService.transferFromPokerManager(transferInfo).subscribe(
      res => {
        this.resetForm();
        console.log(res);
        this.toastrService.success('Money successful transferred.');
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('Server not responding');
      }
    );
  }

  clear(): void {
    this.createTransferForm();
  }

  resetForm(): void {
    this.createTransferForm();
    this.transferForm.controls['toUserId'].setValue(null);
    this.transferForm.controls['amount'].setValue(null);
    this.transferForm.controls['managerId'].setValue(null);
    this.pokerManagerQuery.ManagerId = null;
    this.pokerManagerList = [];

  }

}
