import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmedValidator} from '../../../../core/helper/password-validator';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-create-reagional-manager',
  templateUrl: './create-reagional-manager.component.html',
  styleUrls: ['./create-reagional-manager.component.scss']
})
export class CreateReagionalManagerComponent implements OnInit {
  public managerForm: FormGroup;

  constructor(private adminService: AdminService,
              private translateService: TranslateService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.createManagerForm();

  }

  createManagerForm(): void {
    this.managerForm = new FormGroup({
      phoneNumber: new FormControl('', Validators.required),
      fullName: new FormControl('Regional Manager',[]),
      percent: new FormControl(0, Validators.required),
      password: new FormControl('', Validators.required),
      passwordConf: new FormControl('', Validators.required),
      weeklyStart: new FormControl(0, Validators.required),
      preferredLanguage: new FormControl('en', Validators.required),
    }, {
      updateOn: 'change',
      validators: ConfirmedValidator('password', 'passwordConf')
    });

  }

  create(): void{
    console.log(this.managerForm.value)

    this.managerForm.markAllAsTouched();
    if (this.managerForm.invalid) { return; }

    this.adminService.createManager(this.managerForm.value).subscribe(
      res => {
        console.log(res);
        this.createManagerForm();
        this.toastrService.success('Regional manager is successful created.');
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('We failed to create regional manager.');
      }
    );
  }

  clear(): void {
    this.createManagerForm();
  }

}
