import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RegionalManagerService} from '../../../../core/services/regional-manager/regional-manager.service';
import {ConfirmedValidator} from '../../../../core/helper/password-validator';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from "@ngx-translate/core";
import {concatMap, tap} from 'rxjs/operators';
import {ManagerUserWeekQuery} from '../../../../core/models/ManagerUserWeekQuery';
import {DataService} from '../../../../core/services/data/data.service';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-create-poker-manager',
  templateUrl: './create-poker-manager.component.html',
  styleUrls: ['./create-poker-manager.component.scss']
})
export class CreatePokerManagerComponent implements OnInit {
  public managerForm: FormGroup;
  public balance: number = null;
  public weeks = [];
  public weekIndex = 0;
  public query = null;
  public user = null;
  constructor(private regionalManagerService: RegionalManagerService,
              private translateService: TranslateService,
              private dataService: DataService,
              private store: Store,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.user = this.store.snapshot().eliteAuth.user;

    this.getWeeak();
    this.createManagerForm();
  }

  createManagerForm(): void {
    this.managerForm = new FormGroup({
      phoneNumber: new FormControl('', Validators.required),
      fullName: new FormControl('PokerManager', Validators.required),
      password: new FormControl('', Validators.required),
      passwordConf: new FormControl('', Validators.required),
      weeklyStart: new FormControl(0, Validators.required),
      percent: new FormControl(0, Validators.required),
      preferredLanguage: new FormControl('en', Validators.required),
    }, {
      updateOn: 'change',
      validators: ConfirmedValidator('password', 'passwordConf')
    });

  }

  getWeeak(): void {
    this.dataService.getWeeks().pipe(
      tap((res) => console.log(res)),
      tap(weeks => {
        this.weeks = weeks;
        return this.query = new ManagerUserWeekQuery(1, 10,  weeks[0].id, this.user.userId);
      }),
      concatMap(() => {
        return  this.regionalManagerService.getMyStatus(this.query); }
      )).subscribe(
      res => {
        this.balance = res?.managerCurrentBalance;
        console.log(res);
      },
      err => console.log(err)
    );
  }

  create(): void{
    console.log(this.managerForm.value)

    this.managerForm.markAllAsTouched();
    if (this.managerForm.invalid) { return; }

    this.regionalManagerService.createPokerManager(this.managerForm.value).subscribe(
      res => {
        console.log(res)
        this.createManagerForm();
        setTimeout(() => {
          this.getWeeak();
        }, 100);
        this.toastrService.success('Poker manager is successful created.');
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('We failed to create Poker Manager.');
      }
    )
  }

  clear(): void {
    this.createManagerForm();
  }
}
