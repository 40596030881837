import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appMoveToNext]'
})
export class MoveToNextDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('keyup', ['$event']) onKeyDown(e: any) {
    if (e.srcElement.maxLength === e.srcElement.value.length) {

      e.preventDefault();

      var nextEl = this.findNextTabStop(document.activeElement);
      nextEl.focus();
    }
  }

  findNextTabStop(el) {
    var universe = document.querySelectorAll(
      "input, button, select, textarea, a[href]"
    );
    var list = Array.prototype.filter.call(universe, function(item) {
      return item.tabIndex >= "0";
    });
    var index = list.indexOf(el);
    return list[index + 1] || list[0];
  }
}
