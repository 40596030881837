import {Component, OnInit, ViewChild} from '@angular/core';
import {WeekEnum} from '../../../../core/enum/WeekEnum';
import {ManagerUserWeekQuery, ManagerWeekQuery} from '../../../../core/models/ManagerUserWeekQuery';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ManagerService} from '../../../../core/services/manager/manager.service';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../../core/services/data/data.service';
import {concatMap, tap} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-managers-status',
  templateUrl: './managers-status.component.html',
  styleUrls: ['./managers-status.component.scss']
})
export class ManagersStatusComponent implements OnInit {
  public weekEnum = WeekEnum;
  public weekIndex;
  public query: ManagerUserWeekQuery = new ManagerUserWeekQuery(1, 10);
  public managerList = [];
  public totals = null;
  public collectionSize = null;
  public specUserManagerData = null;
  public changePasswordForm: FormGroup;
  @ViewChild('closeModal') closeModal;
  public selectedUser = null;
  public weeklyStartForm;

  @ViewChild('closeWeekModal') closeWeekModal;
  constructor(private managerService: ManagerService,
              private adminService: AdminService,
              private route: ActivatedRoute,
              private toastrService: ToastrService,
              private translateService: TranslateService,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.createUpdateWeeklyStartForm(null)
    this.initPasswordForm(null)

    this.route.params.subscribe((params) => {
      this.weekIndex = params.id;
      console.log('paramsss', params)
      this.dataService.getWeeks().pipe(
        tap((res) => console.log(res)),
        concatMap(weeks => {
          this.query = new ManagerWeekQuery(1, 10,  weeks[params.id].id);
          return  this.managerService.getManagerWeek(this.query)}
        )).subscribe(
        res => {
          this.collectionSize = res.count;
          this.totals = res.totals;
          this.managerList = res.result;
          console.log(res);
        },
        err => console.log(err)
      )
    })

  }

  initPasswordForm(id): void {

    console.log('init pass')
    this.changePasswordForm = new FormGroup({
      userId: new FormControl(id, Validators.required),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, {
      updateOn: 'change'
    });

  }


  change(){
    console.log(this.changePasswordForm.value)
    this.adminService.changePassword(this.changePasswordForm.value).subscribe(
      res => {
        console.log(res);
        this.closeModal.nativeElement.click();
        this.toastrService.success('The password is a change');

      },
      err => console.log(err)
    )
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getManagerWeek();
  }

  getManagerWeek(): void{
    this.managerService.getManagerWeek(this.query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.managerList = res.result;
        this.totals = res.totals;
      },
      err => console.log(err)
    )
  }

  selectSpecUser(manager): void {
    this.specUserManagerData = manager;
    console.log(this.specUserManagerData)
  }


  changeWeeklyStart(): void {
    console.log(this.weeklyStartForm.value);
    this.adminService.changeWeeklyStart(this.weeklyStartForm.value, this.selectedUser.userId).subscribe(
      res => {
        console.log(res);
        this.getManagerWeek();
        this.closeWeekModal.nativeElement.click();

      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('We failed to change weekly start.');
      }
    );
  }


  selectUser(user): void {
    this.selectedUser = user;
    this.createUpdateWeeklyStartForm(user);
  }

  createUpdateWeeklyStartForm(user): void {
    this.weeklyStartForm = new FormGroup({
      weeklyStart: new FormControl(user?.weeklyStart || 0, Validators.required),
      percent: new FormControl(user?.percent || 0, Validators.required),
    }, {
      updateOn: 'change'
    });
  }

}
