import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.scss']
})
export class PlayerListComponent implements OnInit {
  userList: any[] = [];
  public changePasswordForm: FormGroup;
  public collectionSize = null;
  public selectedUser = null;

  public query = {
    Role: 0,
    PageNumber: 1,
    PageSize: 10
  };
  @ViewChild('closeModal') closeModal;
  @ViewChild('deleteUser') deleteUser;
  constructor(private adminService: AdminService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.initPasswordForm(null)

    this.getPlayers(this.query);

  }

  getPlayers(query): void {
    this.adminService.getAllManager(query).subscribe(
      res => {
        console.log(res);
        this.collectionSize = res.count;
        this.userList = res.result;
      },
      err => console.log(err),
    )
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getPlayers(this.query);
  }


  initPasswordForm(user): void {
    this.changePasswordForm = new FormGroup({
      userId: new FormControl(user?.userId || null, Validators.required),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, {
      updateOn: 'change'
    });

  }

  change(): void {
    console.log(this.changePasswordForm.value)
    this.adminService.changePassword(this.changePasswordForm.value).subscribe(
      res => {
        console.log(res);
        this.closeModal.nativeElement.click();
        this.toastrService.success('The password is a change');

      },
      err => console.log(err)
    )
  }

  deletePlayerModal(player): void {
    this.selectedUser = player;
  }

  deletePlayer({userId}): void {
    console.log('nece klik');
    this.adminService.deleteUser(userId).subscribe(
      res => {
        console.log(res);
        this.getPlayers(this.query);
        this.deleteUser.nativeElement.click();
        this.toastrService.success('Player is Deleted');
      },
      err => {
        console.log(err);
        this.deleteUser.nativeElement.click();
      }
    );
  }


}
