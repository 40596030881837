import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionalManagerService {

  constructor(private http: HttpClient) { }


  createPokerManager(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/regionmanager/manager`, data);
  }

  changeUserPassword(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/regionmanager/changepassword`, data);
  }

  editPlayer(data: any, userId: any): Observable<any> {
    return this.http.put<any>( environment.serverPokerURL + `/regionmanager/user/${userId}`, data);
  }

  getUserListForManager(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/regionmanager/manager`, {params: {...data}});
  }


  getManagerPlayers(): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/regionmanager/users`);
  }

  getMyManagers(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/regionmanager/managers`, {params:{...data}});
  }
  getMyUsers(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/regionmanager/users`, {params:{...data}});
  }

  blockUser(userId, data): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/regionmanager/${userId}/blockuser`, data);
  }

  unblockUser( userId, data): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/regionmanager/${userId}/unblockuser`, data);
  }

  deleteUser(userId: any): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/regionmanager/${userId}/delete`, {});
  }

  getMyStatus(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/regionmanager/managerstats`, {params: {...data}});
  }

  transferFromManager(data: any): Observable<any> {
    return this.http.post<any>( environment.serverAccountsURL + `/account/transferfromsub`, data);
  }

  transferToManager(data: any): Observable<any> {
    return this.http.post<any>( environment.serverAccountsURL + `/account/transfer`, data);
  }

  changeWeeklyStart(data, userId: any): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/regionmanager/manager/${userId}/weeklystart`, data);
  }
}
