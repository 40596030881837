import {Component, OnInit} from '@angular/core';
import {SearchForUser} from '../../../../core/models/ManagerUserWeekQuery';
import {AdminService} from '../../../../core/services/admin/admin.service';
import {ToastrService} from 'ngx-toastr';
import {RoleEnum} from '../../../../core/enum/Role';

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent implements OnInit {

  public userList = [];
  public collectionSize = null;
  public roles = RoleEnum;
  public query = new SearchForUser(1, 10, '');

  constructor(private adminService: AdminService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.search()
  }

  search(): void {
    this.adminService.searchForUser(this.query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.userList = res.result;
      },
      err => console.log(err)
    )
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.search();
  }


}
