import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUserWeek(data): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/adminmanager/users`, {params:{...data}});
  }

  createPlayer(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/manager/user`, data);
  }

  changeUserPassword(data: any): Observable<any> {
    return this.http.post<any>( environment.serverURL + `/manager/changepassword`, data);
  }

  GetUserStatusHistory(data): Observable<any> {
    return this.http.get<any>( environment.serverURL + `/manager/userstatushistory`, {params: data});
  }

  unblockUser(data, userId): Observable<any> {
    return this.http.put<any>( environment.serverURL + `/manager/${userId}/unblockuser`, data);
  }
}
