import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ManagerPlayerQuery, ManagerUserWeekQuery} from '../../../../core/models/ManagerUserWeekQuery';
import {Store} from '@ngxs/store';
import {ManagerService} from '../../../../core/services/manager/manager.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {concatMap, tap} from "rxjs/operators";
import {DataService} from "../../../../core/services/data/data.service";

@Component({
  selector: 'app-transfer-to-player',
  templateUrl: './transfer-to-player.component.html',
  styleUrls: ['./transfer-to-player.component.scss']
})
export class TransferToPlayerComponent implements OnInit {
  public transferForm: FormGroup;
  public transferToManager = '1';
  public userList = [];
  public query = null;
  public user;
  public managerInfo;
  public weeks = [];
  public weekIndex = 0;
  public balance: number = null;

  constructor(private managerService: ManagerService,
              private toastrService: ToastrService,
              private translateService: TranslateService,
              private dataService: DataService,
              private store: Store) { }

  ngOnInit(): void {
    const id = this.store.snapshot().eliteAuth.user.userId;
    this.query = new ManagerPlayerQuery(1, 10000, id);
    this.user = this.store.snapshot().eliteAuth.user;

    this.loadPlayers();
    this.createTransferForm();
    this.getWeeak();
  }

  getWeeak(): void {
    this.dataService.getWeeks().pipe(
      tap((res) => console.log(res)),
      tap(weeks => {
        this.weeks = weeks;
        return this.query = new ManagerUserWeekQuery(1, 1000,  weeks[0].id, this.user.userId);
      }),
      concatMap(() => {
        return  this.managerService.getMyStatus(this.query); }
      )).subscribe(
      res => {
        this.managerInfo = res;
        console.log(res)
        // this.transferForm.controls['toUserId'].setValue(this.managerInfo[0].userId)
        this.balance = this.managerInfo?.managerCurrentBalance;
        console.log(res);
      },
      err => console.log(err)
    );
  }


  createTransferForm(): void {
    this.transferForm = new FormGroup({
      toUserId: new FormControl('', Validators.required),
      amount: new FormControl('', Validators.required),
      otpNumber: new FormControl(1, Validators.required),
      description: new FormControl('', []),
    }, {
      updateOn: 'change'
    });
  }

  loadPlayers(): void{
    this.managerService.getManagerPlayers(this.query).subscribe(
      res => {
        this.userList = res.result;
        if(res.result.length) {
          this.transferForm.controls['toUserId'].setValue(res.result[0].userId)
        }
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('Server not responding');
      }
    )
  }


  transfer(): void {

    this.transferForm.markAllAsTouched();
    if (this.transferForm.invalid) { return; }

    if(this.transferToManager === '1') {
      this.transferTo(this.transferForm.value);
    }

    if(this.transferToManager === '0') {
      this.transferFrom(this.transferForm.value);
    }

  }


  transferTo(data): void {

    const transferInfo = {
      toUserId: data.toUserId,
      amount: data.amount,
      otpNumber: data.otpNumber,
      description: data.description,
    };

    this.managerService.transferToManager(transferInfo).subscribe(
      res => {
        this.createTransferForm();
        this.getWeeak();
        this.toastrService.success('Money successful transferred.');
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('Server not responding');
      }
    );
  }

  transferFrom(data): void {

    const transferInfo = {
      fromUserId: data.toUserId,
      amount: data.amount,
      otpNumber: data.otpNumber,
      description: data.description,
    };

    this.managerService.transferFromManager(transferInfo).subscribe(
      res => {
        this.createTransferForm();
        this.toastrService.success('Money successful transferred.');
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('Server not responding');
      }
    );
  }

  clear(): void {
    this.createTransferForm();
  }

}
