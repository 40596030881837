import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MoveToNextDirective} from './directives/moveToNext/move-to-next.directive';
import {OnlyNumberDirective} from './directives/only-number/only-number.directive';
import {ClickOutsideDirective} from './directives/click-outside/click-outside.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';     //accordion and accordion tab

@NgModule({
  declarations: [
    MoveToNextDirective,
    OnlyNumberDirective,
    ClickOutsideDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    MoveToNextDirective,
    OnlyNumberDirective,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideDirective
  ]
})
export class ShareModule { }
