import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmedValidator} from '../../../../core/helper/password-validator';
import {ManagerService} from '../../../../core/services/manager/manager.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {concatMap, tap} from 'rxjs/operators';
import {ManagerUserWeekQuery} from '../../../../core/models/ManagerUserWeekQuery';
import {DataService} from '../../../../core/services/data/data.service';

@Component({
  selector: 'app-create-player',
  templateUrl: './create-player.component.html',
  styleUrls: ['./create-player.component.scss']
})
export class CreatePlayerComponent implements OnInit {
  public userForm: FormGroup;
  public balance: number = null;
  public weekIndex = 0;
  public weeks = [];
  public totals = null;
  public managerInfo;
  public user;
  public query: ManagerUserWeekQuery = new ManagerUserWeekQuery(1, 10, 0, null);

  constructor(private managerService: ManagerService,
              private translateService: TranslateService,
              private store: Store,
              private dataService: DataService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.user = this.store.snapshot().eliteAuth.user;

    this.createManagerForm();
    this.getMenager();
  }

  getMenager(): void {
    this.dataService.getWeeks().pipe(
      tap((res) => console.log(res)),
      tap(weeks => {
        this.weeks = weeks;
        return this.query = new ManagerUserWeekQuery(1, 10,  weeks[this.weekIndex].id, this.user.userId);
      }),
      concatMap(() => {
        return  this.managerService.getMyStatus(this.query);}
      )).subscribe(
      res => {
        this.managerInfo = res;
        this.balance = this.managerInfo?.managerCurrentBalance;
        console.log(this.balance);
      },
      err => console.log(err)
    );
  }

  createManagerForm(): void {
    this.userForm = new FormGroup({
      phoneNumber: new FormControl('', Validators.required),
      fullName: new FormControl('', [Validators.required, Validators.maxLength(5)]),
      password: new FormControl('', Validators.required),
      passwordConf: new FormControl('', Validators.required),
      weeklyStart: new FormControl('', Validators.required),
      preferredLanguage: new FormControl('en', Validators.required),
    }, {
      updateOn: 'change',
      validators: ConfirmedValidator('password', 'passwordConf')
    });

  }


  create(): void{
    console.log(this.userForm);

    this.userForm.markAllAsTouched();
    if (this.userForm.invalid) { return; }

    if (this.balance < this.userForm.value.weeklyStart) {
      this.toastrService.error('Manager not have enough money.');
      return;
    }

    this.managerService.createPlayer(this.userForm.value).subscribe(
      res => {
        console.log(res);
        this.createManagerForm();
        setTimeout(() => {
          this.getMenager();
        }, 100);
        this.toastrService.success('Player is successful created.');
      },
      async err => {
        console.log(err);
        if (err.error && err.error.systemMessage) {
          const msg = await this.translateService.get(err.error.systemMessage).toPromise();
          this.toastrService.error(msg);
          return;
        }
        this.toastrService.error('We failed to create player.');
      }
    );
  }

  clear(): void {
    this.createManagerForm();
  }

}
