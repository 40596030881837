import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ErrorLogService {

  constructor(private httpClient: HttpClient) { }

  logError(error): any {
    return this.httpClient.post('base-url', error);
  }

  getErrors(params: any): Observable<any> {
    const headers = new HttpHeaders().set('api-key', 'err6v9y$B&EuH@Mc');

    return this.httpClient.get<any>( environment.errorURL + `/errors`, {headers, params});
  }

}
