import {Component, OnInit, ViewChild} from '@angular/core';
import {WeekEnum} from '../../../../../core/enum/WeekEnum';
import {RegionalManagerQuery, RegionalManagerWeekQuery} from '../../../../../core/models/ManagerUserWeekQuery';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RegionalManagerService} from '../../../../../core/services/regional-manager/regional-manager.service';
import {AdminService} from '../../../../../core/services/admin/admin.service';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngxs/store';
import {DataService} from '../../../../../core/services/data/data.service';
import {concatMap, tap} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-regional-roulette-users-weekly',
  templateUrl: './regional-roulette-users-weekly.component.html',
  styleUrls: ['./regional-roulette-users-weekly.component.scss']
})
export class RegionalRouletteUsersWeeklyComponent implements OnInit {

  public weekEnum = WeekEnum;
  public weekIndex;
  public query: RegionalManagerQuery = new RegionalManagerQuery(1, 10, null);
  public myMangerList = [];
  public managerList = [];
  public totals = null;
  public collectionSize = null;
  public changePasswordForm: FormGroup;
  @ViewChild('closeModal') closeModal;
  constructor(private regionalManagerService: RegionalManagerService,
              private adminService: AdminService,
              private route: ActivatedRoute,
              private store: Store,
              private toastrService: ToastrService,
              private dataService: DataService) { }

  ngOnInit(): void {
    this.initPasswordForm(null)
    // this.route.params.subscribe((params) => {
    //   this.weekIndex = params.id;
    //   console.log('paramsss', params)
    //   this.dataService.getWeeks().pipe(
    //     tap((res) => console.log(res)),
    //     concatMap(weeks => {
    //       this.query = new RegionalManagerWeekQuery(1, 10,  weeks[0].id);
    //       return  this.regionalManagerService.getMyUsers(this.query)}
    //     )).subscribe(
    //     res => {
    //       this.collectionSize = res.count;
    //       this.totals = res.totals;
    //       this.managerList = res.result;
    //       console.log(res);
    //     },
    //     err => console.log(err)
    //   )
    // })
    const myId = this.store.snapshot().eliteAuth.user.userId

    this.route.params.subscribe((params) => {
      this.weekIndex = params.id
      this.dataService.getWeeks().pipe(
        tap((res) => console.log(res)),
        concatMap(weeks => {
          this.query = new RegionalManagerQuery(1, 10,  weeks[params.id].id);
          const data = {
            Role: 100,
            PageNumber: 1,
            PageSize: 1000
          };
          return this.regionalManagerService.getMyManagers(data);
        }),
        concatMap(managersData => {
          this.query.ManagerId = myId;
          delete this.query.Role;
          this.myMangerList = managersData.result;
          return  this.regionalManagerService.getMyUsers(this.query)}
        )).subscribe(
        res => {
          this.managerList = res.result;
          this.collectionSize = res.count;
          this.totals = res.totals;
          console.log(res);
        },
        err => console.log(err)
      )
    })


  }

  initPasswordForm(id): void {

    console.log('init pass')
    this.changePasswordForm = new FormGroup({
      userId: new FormControl(id, Validators.required),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, {
      updateOn: 'change'
    });
  }

  change(): void{
    console.log(this.changePasswordForm.value)
    this.adminService.changePassword(this.changePasswordForm.value).subscribe(
      res => {
        console.log(res);
        this.closeModal.nativeElement.click();
        this.toastrService.success('The password is a change');
      },
      err => console.log(err)
    )
  }

  changePage(page): void {
    this.query.PageNumber = +page;
    this.getManagerWeek();
  }

  getManagerWeek(): void{
    console.log('wwwwwwwww', this.query)
    this.regionalManagerService.getMyUsers(this.query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.managerList = res.result;
        this.totals = res.totals;
      },
      err => console.log(err)
    )
  }

}
