import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  constructor(private http: HttpClient) { }

  getWeeks(): Observable<any> {
    return this.http.get<any>( environment.serverPokerURL + `/admin/lastweeks`);
  }

  getAccountBalance(): Observable<any> {
    return this.http.get<any>( environment.serverAccountsURL + `/account`);
  }

  // post(data: any): Observable<any> {
  //   return this.http.post<any>( environment.serverURL + `/user`, data);
  // }
  //
  // createPlayer(data: any): Observable<any> {
  //   return this.http.post<any>( environment.serverURL + `/manager/user`, data);
  // }
  //
  // changeUserPassword(data: any): Observable<any> {
  //   return this.http.post<any>( environment.serverURL + `/manager/changepassword`, data);
  // }
  //
  //
  //
  // unblockUser(data, userId): Observable<any> {
  //   return this.http.put<any>( environment.serverURL + `/manager/${userId}/unblockuser`, data);
  // }
}
