import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminService} from '../../../core/services/admin/admin.service';
import {ManagerUserWeekQuery} from '../../../core/models/ManagerUserWeekQuery';
import {ManagerService} from '../../../core/services/manager/manager.service';
import {concatMap, tap} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-regional-manager-managers',
  templateUrl: './regional-manager-managers.component.html',
  styleUrls: ['./regional-manager-managers.component.scss']
})
export class RegionalManagerManagersComponent implements OnInit {
  public changePasswordForm: FormGroup;
  public managerList = [];
  public userList = [];
  public collectionSize = null;
  public selectedUser = null;
  public query = new ManagerUserWeekQuery(1, 10, null, null);
  @ViewChild('closeModal') closeModal;
  @ViewChild('deleteUser') deleteUser;

  constructor(private adminService: AdminService,
              private toastrService: ToastrService,
              private managerService: ManagerService) { }

  ngOnInit(): void {
    this.initPasswordForm(null);

    const data = {
      Role: 101,
      PageNumber: 1,
      PageSize: 1000
    };
    this.adminService.getAllManager(data).pipe(
      tap((res) => console.log(res)),
      concatMap(managerList => {
        this.managerList = managerList.result;
        this.query = new ManagerUserWeekQuery(1, 10, 0, this.managerList[0].userId);
        return  this.adminService.getUserListByManager(this.query)}
      )).subscribe(
      res => {
        this.collectionSize = res.count;
        this.userList = res.result;
        console.log(res);
      },
      err => console.log(err)
    )
  }

  getAllManager(): void {
    const data = {
      Role: 101,
      PageNumber: 1,
      PageSize: 1000
    };
    this.adminService.getAllManager(data).pipe(
      tap((res) => console.log(res)),
      concatMap(managerList => {
        this.managerList = managerList.result;
        this.query = new ManagerUserWeekQuery(1, 10, 0, this.managerList[0].userId);
        return  this.adminService.getUserListByManager(this.query)}
      )).subscribe(
      res => {
        this.collectionSize = res.count;
        this.userList = res.result;
        console.log(res);
      },
      err => console.log(err)
    );
  }

  search(): void {
    this.adminService.getUserListByManager(this.query).subscribe(
      res => {
        this.collectionSize = res.count;
        this.userList = res.result;
      },
      err => console.log(err)
    )
  }

  initPasswordForm(id): void {
    this.changePasswordForm = new FormGroup({
      userId: new FormControl(id, Validators.required),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    }, {
      updateOn: 'change'
    });

  }

  deletePlayerModal(player): void {
    this.selectedUser = player;
  }

  deletePlayer(): void {
    this.adminService.deleteUser(this.selectedUser).subscribe(
      res => {
        console.log(res);
        this.deleteUser.nativeElement.click();
        this.getAllManager();
        this.toastrService.success('Manager is Deleted');
      },
      err => {
        console.log(err);
        this.deleteUser.nativeElement.click();
      }
    );
  }

  change(): void {
    console.log(this.changePasswordForm.value);
    this.adminService.changePassword(this.changePasswordForm.value).subscribe(
      res => {
        console.log(res);
        this.closeModal.nativeElement.click();
        this.toastrService.success('The password is a change');
      },
      err => console.log(err)
    )
  }

}
